import React from "react";
import { InlineWidget } from "react-calendly";

const BookTour = ({
  apartmentName,
  calendlyUrl,
  isMd,
  isLg,
}: {
  apartmentName?: string;
  calendlyUrl?: string;
  isMd: boolean;
  isLg: boolean;
}) => {
  return (
    <div
      style={{
        overflow: "hidden",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
      }}
    >
      {calendlyUrl ? (
        <InlineWidget
          url={calendlyUrl}
          pageSettings={{
            backgroundColor: "fff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "6b7280",
            textColor: "000",
            hideGdprBanner: true,
          }}
          styles={{
            height: "100vh",
            marginTop: isLg || isMd ? "-50px" : "0",
            marginLeft: isLg ? "-30px" : "0",
            marginBottom: isLg ? "5px" : "0",
          }}
          prefill={{
            customAnswers: {
              a1: "",
              a2: apartmentName ? apartmentName : "",
            },
          }}
        />
      ) : (
        <div>Booking tour currently unavailable</div>
      )}
    </div>
  );
};

export default BookTour;
