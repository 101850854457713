import React from "react";
import { ClientData } from "./Types.ts";

const GetInTouch = ({
  clientData,
  isLg,
}: {
  clientData?: ClientData;
  isLg: boolean;
}) => {
  return (
    <div
      style={{
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
        paddingBottom: !isLg ? "64px" : "unset",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "50px",
          padding: "7px 15px",
          background: "#fff",
          borderBottom: "1px solid var(--w-tertiary-color)",
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
        }}
      ></div>
      <div
        style={{
          marginTop: "15px",
          marginBottom: "10px",
          paddingLeft: "15px",
          paddingRight: "15px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#201f1e",
            padding: "10px",
            textAlign: "center",
          }}
        >
          Contact Us
        </div>
        <div
          style={{
            overflowY: "auto",
            width: "100%",
            height: "500px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <iframe
            title="Contact Form"
            allowFullScreen={true}
            allow="geolocation; microphone; camera"
            src={`https://my.forms.app/form/${clientData?.ui.videoWidget.contactFormId}`}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
            }}
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
