import React from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const Dropdown = ({
  item,
  handleItem,
  itemArray,
  itemName,
}: {
  item: number;
  itemName: string;
  handleItem: (value: number) => void;
  itemArray: (string | number)[];
}) => {
  return (
    <Box sx={{ paddingLeft: "5px", paddingRight: "5px", width: "50%" }}>
      <FormControl size="small" variant="standard" fullWidth>
        <InputLabel
          id="demo-simple-select-label"
          style={{ color: "var(--w-primary-color) !important" }}
        >
          # of {itemName}
        </InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={item === 0 ? "Any" : item.toString()}
          onChange={(event: SelectChangeEvent) => {
            if (event.target.value === "Any") {
              handleItem(0);
            } else {
              handleItem(parseInt(event.target.value));
            }
          }}
          sx={{
            color: "var(--w-primary-color) !important",
            borderBottom: "red !important",
            fontSize: "15px",
          }}
        >
          {itemArray.map((num, index) => (
            <MenuItem sx={{ fontSize: "14px" }} key={index} value={num}>
              {num}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Dropdown;
