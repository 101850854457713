import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { ClientData } from "./Types";

const Tour3D = ({
  clientData,
  isLg,
  isSm,
}: {
  clientData?: ClientData;
  isLg: boolean;
  isSm: boolean;
}) => {
  const [tour, setTour] = useState(clientData?.property.tour3d[0].url);

  const handleChange = (event: SelectChangeEvent) => {
    setTour(event.target.value as string);
  };

  return (
    <div
      style={{
        overflowY: "auto",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        height: "100%",
        maxHeight: "100%",
        paddingBottom: !isLg ? "64px" : "unset",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: "50px",
          padding: "7px 15px",
          background: "#fff",
          borderBottom: "1px solid var(--w-tertiary-color)",
          position: "sticky",
          top: 0,
          left: 0,
          right: 0,
        }}
      ></div>
      <div
        style={{
          marginTop: "15px",
          marginBottom: "10px",
          paddingLeft: "15px",
          paddingRight: "15px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: "600",
            color: "#201f1e",
            padding: "10px",
            textAlign: "center",
          }}
        >
          Virtual 3D Tour
        </div>
        <div>
          <Box>
            <FormControl size="small" variant="standard">
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "var(--w-primary-color) !important" }}
              >
                Select Tour
              </InputLabel>
              <Select
                labelId="demo-simple-select-standard-label"
                id="demo-simple-select-standard"
                value={tour}
                onChange={handleChange}
                sx={{
                  color: "var(--w-primary-color) !important",
                  borderBottom: "red !important",
                  fontSize: "15px",
                  width: isSm ? "100px" : "150px",
                }}
              >
                {clientData?.property.tour3d &&
                  clientData?.property.tour3d.map((tour, index) => (
                    <MenuItem
                      sx={{ fontSize: "14px" }}
                      key={index}
                      value={tour.url}
                    >
                      {tour.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </div>
      </div>
      <iframe
        src={tour}
        title="Touring floor plans"
        width="100%"
        height="100%"
        style={{ border: "none" }}
        allowFullScreen
      />
    </div>
  );
};

export default Tour3D;
