import React, { useEffect, useRef, useState } from "react";
import LeftArrow from "./LeftArrow";
import RightArrow from "./RightArrow";
import { CarouselContent, CarouselItem, Post } from "./InstagramFeed";
import { useSwipeable } from "react-swipeable";

const InstagramPost = ({
  post,
  id,
  currentCarousel,
  handleRightArrowClick,
  handleLeftArrowClick,
}: {
  post: Post;
  id: string;
  currentCarousel?: CarouselItem;
  handleRightArrowClick: () => void;
  handleLeftArrowClick: () => void;
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const handleVideoPlay = () => {
      videoElement.currentTime = 0;

      // Delay playing until after pausing and resetting currentTime
      setTimeout(() => {
        videoElement.play().catch((error) => {
          console.error("Error playing video:", error);
        });
      }, 100);
    };

    const handleVideoPause = () => {
      videoElement.pause();
    };

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            handleVideoPlay();
          } else {
            handleVideoPause();
          }
        });
      },
      { threshold: 0.7 } // Trigger when 70% of the video is in view
    );

    // Observe the video element
    observer.observe(videoElement);

    // Cleanup observer on component unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  const toggleMute = () => {
    setIsMuted((prev) => !prev);
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => handleRightArrowClick(),
    onSwipedRight: () => handleLeftArrowClick(),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  const formatDate = (isoDate: string): string => {
    const postDate = new Date(isoDate);
    const currentDate = new Date();

    const diffTime = Math.abs(currentDate.getTime() - postDate.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); // Difference in days

    if (diffDays <= 7) {
      return `${diffDays} day${diffDays > 1 ? "s" : ""} ago`;
    } else {
      const options: Intl.DateTimeFormatOptions = {
        day: "numeric",
        month: "short",
        year: "numeric",
      };
      return postDate.toLocaleDateString(undefined, options);
    }
  };

  if (!currentCarousel?.carouselContent.length && !post.media_url)
    return (
      <div
        style={{
          fontSize: "18px",
          position: "absolute",
          top: "50%",
          left: "40%",
        }}
      >
        Loading...
      </div>
    );

  const currentMedia: CarouselContent = currentCarousel?.carouselContent[
    currentCarousel.currentIndex
  ] || {
    media_url: post.media_url,
    media_type: post.media_type,
    thumbnail_url: post.thumbnail_url,
    permalink: post.permalink,
    id: post.id,
  };

  const imgEl = (
    <img
      src={currentMedia.media_url}
      alt=""
      style={{
        maxHeight: "80vh",
        height: "auto",
        minHeight: "60vh",
        width: "100%",
        objectFit: "cover",
      }}
    />
  );
  const videoEl = (
    <div onClick={toggleMute} style={{ cursor: "pointer" }}>
      <video
        ref={videoRef}
        src={currentMedia.media_url}
        poster={currentMedia.thumbnail_url}
        webkit-playsinline="true"
        playsInline
        muted={isMuted}
        // autoPlay
        loop
        style={{
          maxHeight: "80vh",
          height: "auto",
          minHeight: "60vh",
          width: "100%",
          objectFit: "cover",
        }}
      ></video>
      <div
        style={{
          position: "absolute",
          bottom: "15px",
          right: "15px",
        }}
      >
        <button
          style={{
            background: "rgba(0,0,0,0.5)",
            border: "none",
            padding: "0.5rem",
            borderRadius: "9999px",
            display: "flex",
            pointerEvents: "none",
            cursor: "pointer",
          }}
          onClick={toggleMute}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            style={{ color: "#fff", width: "1.25rem", height: "1.25rem" }}
          >
            {isMuted ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
              ></path>
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
              ></path>
            )}
          </svg>
        </button>
      </div>
    </div>
  );

  return (
    <div id={id} {...swipeHandlers}>
      <div
        style={{
          width: "100%",
          height: "1px",
          background: "#e3e3e3",
        }}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "50px",
          padding: "0 5px",
        }}
      >
        <a
          href={"https://instagram.com/" + post.username}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            background: "none",
            fontSize: "16px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            color: "#000",
            padding: "4px",
            borderRadius: "5px",
            fontWeight: "600",
          }}
        >
          {post.username}
        </a>
        <div>
          <a
            href={post.permalink}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              background: "none",
              fontSize: "16px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              color: "rgb(0, 149, 246)",
              padding: "4px",
              borderRadius: "5px",
              fontWeight: "600",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              strokeWidth="1.8"
              stroke="currentColor"
              fill="none"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#000",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"
              ></path>
            </svg>
          </a>
        </div>
      </div>

      {post.media_type === "CAROUSEL_ALBUM" && currentCarousel ? (
        <div
          style={{
            position: "relative",
            maxHeight: "80vh",
            minHeight: "60vh",
            width: "100%",
            objectFit: "cover",
          }}
        >
          {currentMedia.media_type === "VIDEO" ? (
            <video
              src={currentMedia.media_url}
              poster={currentMedia.thumbnail_url}
              webkit-playsinline="true"
              playsInline
              muted
              autoPlay
              loop
              style={{
                maxHeight: "80vh",
                height: "auto",
                minHeight: "60vh",
                width: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            imgEl
          )}
          <div
            style={{
              display: "flex",
              position: "absolute",
              top: "43%",
              width: "100%",
              justifyContent: "space-between",
            }}
          >
            {currentCarousel.currentIndex > 0 && (
              <LeftArrow handleLeftArrowClick={handleLeftArrowClick} />
            )}
            {currentCarousel.currentIndex <
              currentCarousel.carouselContent.length - 1 && (
              <RightArrow handleRightArrowClick={handleRightArrowClick} />
            )}
          </div>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          {currentMedia.media_type === "VIDEO" ? videoEl : imgEl}
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          height: "auto",
          width: "100%",
          marginTop: "10px",
          marginBottom: "5px",
          padding: "0 5px",
        }}
      >
        <div
          style={{
            color: "#000",
            padding: "2px 5px",
            fontSize: "15px",
            width: "auto",
            maxWidth: "100%",
            scrollbarWidth: "thin",
            textWrap: "wrap",
            fontWeight: "400",
          }}
        >
          <a
            href={"https://instagram.com/" + post.username}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", fontWeight: "600", color: "#000" }}
          >
            {post.username}
          </a>{" "}
          {post.caption}
        </div>
      </div>
      <div
        style={{
          color: "#7d7c7c",
          paddingLeft: "10px",
          marginBottom: "15px",
          fontSize: "11px",
          fontWeight: "400",
        }}
      >
        {formatDate(post.timestamp).toLocaleUpperCase()}
      </div>
    </div>
  );
};

export default InstagramPost;
