import React from "react";
import PlayButton from "./PlayButton";
import { PageData } from "./VideoWidget";
import PlanButton from "./PlanButton";
interface VideoProps {
  currentData: PageData;
  handleToggleVideo: () => void;
  videoRef: React.RefObject<HTMLVideoElement>;
  isVideoPaused: boolean;
  isLg: boolean;
  isSm: boolean;
  isShownMenu: boolean;
  isShownInstagram: boolean;
  isActiveMenuButtons: boolean;
  toggleShowAmenities: () => void;
  isShownAmenities: boolean;
  thumbnail?: string;
}

const NUM_OF_ROWS = 3;

const VideoComponent = ({
  currentData,
  handleToggleVideo,
  videoRef,
  isVideoPaused,
  isSm,
  isLg,
  isShownMenu,
  isShownInstagram,
  isActiveMenuButtons,
  toggleShowAmenities,
  isShownAmenities,
  thumbnail,
}: VideoProps) => {
  return (
    <>
      <div
        onClick={handleToggleVideo}
        style={{
          position: "absolute",
          overflow: "hidden",
          backgroundColor: "#fff",
          top: isActiveMenuButtons ? "80px" : "0px",
          right: isActiveMenuButtons ? "10px" : "0px",
          borderRadius: isActiveMenuButtons ? "15px" : "0px",
          width: isActiveMenuButtons ? "120px" : "100%",
          height: isActiveMenuButtons ? "120px" : "100%",
          transition: "all 0.4s ease-in-out",
          opacity: isActiveMenuButtons ? "0" : "1",
          zIndex: isActiveMenuButtons ? -1 : "auto",
          display: isActiveMenuButtons ? "none" : "block",
        }}
      >
        <video
          src={currentData.videoUrl}
          poster={thumbnail}
          playsInline
          autoPlay
          loop
          ref={videoRef}
          style={{
            zIndex: "10",
            position: "absolute",
            width: "100%",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
          }}
        />
        <div
          style={{
            opacity:
              isVideoPaused ||
              (isShownMenu && !isLg) ||
              (isShownAmenities && currentData.additionalInfo) ||
              isShownInstagram
                ? "0.4"
                : "0",
            transition: "opacity 0.2s ease 0s",
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "#000000",
            zIndex: 10,
            pointerEvents: "none",
          }}
        />
      </div>
      <PlayButton
        isShownMenu={isShownMenu}
        isShownInstagram={isShownInstagram}
        isActiveMenuButtons={isActiveMenuButtons}
        isVideoPaused={isVideoPaused}
        handleToggleVideo={handleToggleVideo}
        isShownAmenities={isShownAmenities}
        additionalInfo={!!currentData.additionalInfo}
        isLg={isLg}
      />

      {/* plans */}
      {/* gradient behind the plans  */}
      {!isActiveMenuButtons && (
        <>
          <div
            style={{
              opacity: "0.5",
              transition: "all 0.2s ease 0s",
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: 10,
              pointerEvents: "none",
              backgroundImage:
                "linear-gradient(to top, rgba(0,0,0,100), rgba(0, 0, 0, 0))",
              overflow: "hidden",
            }}
          ></div>
          <div
            style={{
              position: "absolute",
              zIndex: 10,
              width: "100%",
              pointerEvents: "none",
              bottom: 0,
              display: "flex",
              flexDirection: "column",
              color:
                (isShownMenu && !isLg) ||
                (currentData.additionalInfo && isShownAmenities) ||
                isShownInstagram
                  ? "#4a4949"
                  : "#fff",
              transition: "transform 0.3s",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                paddingLeft: isLg ? "1.5rem" : "1rem",
                paddingRight: isLg ? "1.5rem" : "1rem",
                fontSize: "20px",
                paddingBottom:
                  currentData.buttons || currentData.additionalInfo
                    ? "0"
                    : isLg
                    ? "1.5rem"
                    : "5.5rem",
              }}
            >
              {currentData.type === "WELCOME" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  style={{
                    width: "20px",
                    height: "20px",
                    marginRight: "8px",
                  }}
                >
                  <path
                    fillRule="evenodd"
                    d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              )}
              {currentData.subtitle}
            </div>
            {currentData.buttons && (
              <>
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "300",
                    marginTop: "10px",
                    marginBottom: "10px",
                    paddingLeft: isLg ? "1.5rem" : "1rem",
                    color:
                      (isShownMenu && !isLg) ||
                      (currentData.additionalInfo && isShownAmenities) ||
                      isShownInstagram
                        ? "#4a4949"
                        : "#fff",
                  }}
                >
                  Where to next?
                </p>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, minmax(0, 1fr))",
                    gap: "0.5rem",
                    paddingLeft: isLg ? "1.5rem" : "1rem",
                    paddingRight: isLg ? "1.5rem" : "1rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "1.5rem",
                    marginBottom: !isLg ? "70px" : 0,
                  }}
                >
                  {currentData.buttons &&
                    currentData.buttons.map((button, index) => (
                      <PlanButton
                        order={String.fromCharCode("A".charCodeAt(0) + index)}
                        text={button.text}
                        onClick={button.onClick}
                        isSm={isSm}
                        key={index}
                        isLg={isLg}
                        isShownMenu={isShownMenu}
                        isShownInstagram={isShownInstagram}
                      />
                    ))}
                </div>
              </>
            )}
            {currentData.additionalInfo &&
              currentData.additionalInfo?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: isLg ? "1.5rem" : "80px",
                    width: isSm ? "100%" : "50%",
                  }}
                >
                  <div
                    style={{
                      borderRadius: "5px",
                      zIndex: 15,
                      fontSize: "13.5px",
                      fontWeight: "300",
                      pointerEvents: "all",
                      marginTop: "10px",
                      textWrap: "wrap",
                      textOverflow: "ellipsis",
                      position: "relative",
                      paddingLeft: isLg ? "1.5rem" : "1rem",
                    }}
                  >
                    <div>
                      <span style={{ fontSize: "14px", fontWeight: "600" }}>
                        {currentData.additionalInfo[0].title}
                      </span>
                      <ul
                        id="w-amenity-list"
                        style={{
                          listStyleType: "disc",
                          paddingLeft: "2rem",
                          marginTop: 0,
                          marginBottom: "1rem",
                        }}
                      >
                        {currentData.additionalInfo[0].features
                          ?.slice(0, NUM_OF_ROWS)
                          .map((item, index) => (
                            <li key={index}>
                              {item}
                              {index === NUM_OF_ROWS - 1 ? (
                                <span>
                                  ...{" "}
                                  <span
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={toggleShowAmenities}
                                  >
                                    more
                                  </span>
                                </span>
                              ) : (
                                ""
                              )}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            {/* when amenities is shown more */}
            {isShownAmenities && currentData.additionalInfo && (
              <div
                style={{
                  opacity: isShownAmenities ? "1" : "0",
                  background: "rgba(var(--w-secondary-color), 0.8)",
                  position: "absolute",
                  zIndex: isShownAmenities ? 30 : -1,
                  width: "100%",
                  height: "50vh",
                  borderTopLeftRadius: "0.75rem",
                  borderTopRightRadius: "0.75rem",
                  bottom: !isLg ? "64px" : 0,
                  left: 0,
                  display: currentData.additionalInfo ? "flex" : "none",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  transition: "all 0.3s ease",
                  boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 20px",
                  overflowY: "auto",
                  pointerEvents: "all",
                  padding: "5px",
                }}
              >
                <button
                  onClick={toggleShowAmenities}
                  style={{
                    borderWidth: 0,
                    cursor: "pointer",
                    background: "none",
                    display: "flex",
                    // justifyContent: "center",
                    // alignItems: "center",
                    marginTop: "10px",
                    marginRight: "15px",
                    alignSelf: "flex-end",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    style={{
                      width: "1.5rem",
                      height: "1.5rem",
                      color: "#000000",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    ></path>
                  </svg>
                </button>
                <div
                  style={{
                    display: "flex",
                    flexDirection: isSm ? "column" : "row",
                    flex: 1,
                    alignSelf: "center",
                    marginTop: isSm ? "5px" : "20px",
                    overflowY: "scroll",
                    scrollbarWidth: "thin",
                    scrollbarColor:
                      "rgba(var(--w-secondary-color), 0.8) transparent",
                    overscrollBehavior: "contain",
                    padding: "0 5px",
                  }}
                >
                  {currentData.additionalInfo &&
                    currentData.additionalInfo.map((addInfo, index) => (
                      <div
                        key={index}
                        style={{
                          color: "#000",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            paddingLeft: "5px",
                          }}
                        >
                          {addInfo.title}
                        </span>
                        {addInfo.features && (
                          <ul
                            id="w-amenity-list"
                            style={{
                              listStyleType: "disc",
                              paddingLeft: "2rem",
                              marginTop: 0,
                              marginBottom: "1rem",
                              fontSize: "13.5px",
                            }}
                          >
                            {addInfo.features?.map((feat, i) => (
                              <li key={i}>{feat}</li>
                            ))}
                          </ul>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default VideoComponent;
