import React, { useEffect, useState } from "react";
import { ClientData } from "./Types";
import axios from "axios";
import InstagramModal from "./InstagramModal";
import InstagramPost from "./InstagramPost";
import SkeletonFeed from "./SkeletonInstagramFeed";

export interface Post {
  id: string;
  caption: string;
  media_url: string;
  media_type: string;
  permalink: string;
  thumbnail_url: string;
  username: string;
  timestamp: string;
  children?: {
    data: {
      id: string;
    }[];
  };
}

export interface User {
  user_id: string;
  username: string;
  name: string;
  profile_picture_url: string;
  id: string;
}

interface ActiveMedia {
  id: number | null;
  status: boolean;
}

interface InstagramFeedProps {
  toggleShowInstagram: () => void;
  clientData?: ClientData;
  isMd: boolean;
  isLg: boolean;
}

const MAX_CHARS = 30;

export interface CarouselContent {
  id: string;
  media_url: string;
  media_type: string;
  permalink: string;
  thumbnail_url: string;
}

export interface CarouselItem {
  postId?: string | number;
  carouselContent: CarouselContent[];
  currentIndex: number;
}
const InstagramFeed = ({
  toggleShowInstagram,
  clientData,
  isMd,
  isLg,
}: InstagramFeedProps) => {
  const [posts, setPosts] = useState<Post[]>();
  const [user, setUser] = useState<User>();
  const [hovered, setHovered] = useState<ActiveMedia>();

  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);

  const [carousel, setCarousel] = useState<CarouselItem[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const handleMouseEnter = (event) => {
    event.currentTarget.play();
  };

  const handleMouseLeave = (event) => {
    event.currentTarget.pause();
    event.currentTarget.currentTime = 1;
  };

  const openModal = (index: number) => {
    setCarousel((prevCarousel) =>
      prevCarousel.map((item) => ({ ...item, currentIndex: 0 }))
    );

    setIsOpen(true);
    setActiveImageIndex(index);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveImageIndex(null);
  };

  const truncatedText = (text: string) => {
    if (text.length <= MAX_CHARS) return text;

    const trimmedText = text.slice(0, MAX_CHARS);
    const lastSpaceIndex = trimmedText.lastIndexOf(" ");

    if (lastSpaceIndex === -1) {
      return trimmedText + "...";
    }

    return trimmedText.slice(0, lastSpaceIndex) + "...";
  };

  useEffect(() => {
    if (isOpen && activeImageIndex !== null) {
      const activeImageElement = document.getElementById(
        `image-${activeImageIndex}`
      );
      if (activeImageElement) {
        activeImageElement.scrollIntoView({
          block: "start",
        });
      }
    }
  }, [isOpen, activeImageIndex]);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (clientData?.id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/instagram-user/${clientData.id}`
          );
          setUser(response.data);
        }
      } catch (error) {
        console.error("There was a problem with the fetching data: ", error);
      }
    };

    const fetchFeed = async () => {
      try {
        if (clientData?.id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/instagram-feed/${clientData.id}`
          );
          setPosts(response.data.data);
          setTimeout(() => setIsLoading(false), 1000);
        }
      } catch (error) {
        console.error("There was a problem with the fetching data: ", error);
        setIsLoading(false);
      }
    };

    fetchUser();
    fetchFeed();
  }, [clientData]);

  useEffect(() => {
    const fetchDataFromCarousel = async () => {
      if (posts && posts?.length > 0) {
        posts?.map(async (post, index) => {
          if (post.media_type === "CAROUSEL_ALBUM") {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/instagram-carousel?smpClientId=${clientData?.id}&postId=${post.id}`
              );
              setCarousel((prevState) => [
                ...prevState,
                {
                  postId: post.id,
                  carouselContent: response.data.data,
                  currentIndex: index,
                },
              ]);
            } catch (error) {
              console.error(
                "There was a problem with the fetching data: ",
                error
              );
            }
          }
        });
      }
    };

    fetchDataFromCarousel();
  }, [posts, clientData?.id]);

  const username = user && user.username;
  const profileUrl = "https://instagram.com/" + username;
  const postEls =
    posts &&
    posts.map((post, index) => {
      // IMAGE or CAROUSEL_ALBUM
      let mediaEl = (
        <img
          src={post.media_url}
          alt=""
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            transform:
              hovered?.id === index && hovered.status
                ? "scale(1.1)"
                : "scale(1)",
            transition: "all 0.3s ease",
            background: "#ccc",
          }}
          loading="lazy"
          onClick={() => openModal(index)}
        />
      );

      // VIDEO
      if (post.media_type === "VIDEO") {
        mediaEl = (
          <video
            poster={post.thumbnail_url}
            src={post.media_url}
            muted
            webkit-playsinline="true"
            playsInline
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              transform:
                hovered?.id === index && hovered.status
                  ? "scale(1.1)"
                  : "scale(1)",
              transition: "all 0.3s ease",
              background: "#ccc",
            }}
            onClick={() => openModal(index)}
          />
        );
      }

      return (
        <div
          style={{
            width: "100%",
            height: "200px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          key={index}
          data-index={index}
          onMouseEnter={() => setHovered({ id: index, status: true })}
          onMouseLeave={() => setHovered({ id: index, status: false })}
        >
          {/* album or video icon */}
          <div
            style={{
              position: "absolute",
              padding: "10px",
              top: 0,
              right: 0,
              zIndex: 20,
              transition: "all 0.3s ease",
              pointerEvents: "none",
              opacity: hovered?.id === index && hovered.status ? "0" : "1",
            }}
          >
            {post.media_type === "VIDEO" ? (
              <svg
                width="20px"
                height="20px"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path
                    d="M14.7295 2H9.26953V6.36H14.7295V2Z"
                    fill="#ffffff"
                  ></path>
                  <path
                    d="M16.2305 2V6.36H21.8705C21.3605 3.61 19.3305 2.01 16.2305 2Z"
                    fill="#ffffff"
                  ></path>
                  <path
                    d="M2 7.85938V16.1894C2 19.8294 4.17 21.9994 7.81 21.9994H16.19C19.83 21.9994 22 19.8294 22 16.1894V7.85938H2ZM14.44 16.1794L12.36 17.3794C11.92 17.6294 11.49 17.7594 11.09 17.7594C10.79 17.7594 10.52 17.6894 10.27 17.5494C9.69 17.2194 9.37 16.5394 9.37 15.6594V13.2594C9.37 12.3794 9.69 11.6994 10.27 11.3694C10.85 11.0294 11.59 11.0894 12.36 11.5394L14.44 12.7394C15.21 13.1794 15.63 13.7994 15.63 14.4694C15.63 15.1394 15.2 15.7294 14.44 16.1794Z"
                    fill="#ffffff"
                  ></path>
                  <path
                    d="M7.76891 2C4.66891 2.01 2.63891 3.61 2.12891 6.36H7.76891V2Z"
                    fill="#ffffff"
                  ></path>
                </g>
              </svg>
            ) : post.media_type === "CAROUSEL_ALBUM" ? (
              <svg
                fill="#fff"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                width="18px"
                height="18px"
                viewBox="0 0 45.964 45.964"
                transform="matrix(-1, 0, 0, 1, 0, 0)"
                stroke="#fff"
              >
                <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <g>
                    <g>
                      <path d="M7.071,30.834V11.062H4.042C1.803,11.062,0,12.893,0,15.13v26.732c0,2.24,1.803,4.051,4.042,4.051h26.733 c2.238,0,4.076-1.811,4.076-4.051v-2.92H15.179C10.733,38.943,7.071,35.281,7.071,30.834z"></path>
                      <path d="M41.913,0.05H15.179c-2.238,0-4.066,1.813-4.066,4.051v26.733c0,2.241,1.829,4.067,4.066,4.067h26.734 c2.237,0,4.051-1.826,4.051-4.067V4.102C45.964,1.862,44.15,0.05,41.913,0.05z M41.363,28.589 c-0.223,0.412-0.652,0.656-1.12,0.656H17.336c-0.403,0-0.782-0.18-1.022-0.502c-0.24-0.324-0.313-0.736-0.197-1.123l3.277-10.839 c0.216-0.713,0.818-1.24,1.554-1.361c0.736-0.12,1.476,0.19,1.908,0.797l4.582,6.437c0.617,0.867,1.812,1.082,2.689,0.484 l4.219-2.865c0.434-0.295,0.967-0.402,1.48-0.299c0.515,0.102,0.966,0.408,1.253,0.848l4.229,6.472 C41.564,27.687,41.585,28.179,41.363,28.589z"></path>
                    </g>
                  </g>
                </g>
              </svg>
            ) : null}
          </div>
          {/* overlay */}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 20,
              background: "rgba(0, 0, 0, 0.6)",
              transition: "all 0.3s ease",
              pointerEvents: "none",
              opacity: hovered?.id === index && hovered.status ? "1" : "0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
                textAlign: "center",
                color: "#fff",
                width: "100%",
                height: "100%",
              }}
            >
              {truncatedText(post.caption)}
            </div>
          </div>

          {mediaEl}
        </div>
      );
    });

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "0px",
        overflowY: "auto",
        padding: "0 5px",
      }}
    >
      <div
        style={{
          width: "95%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          src={`${process.env.REACT_APP_API_URL}/assets/instagram-logo.png`}
          alt="logo"
          style={{ height: "55px" }}
        />
        <button
          onClick={toggleShowInstagram}
          style={{
            borderWidth: 0,
            cursor: "pointer",
            background: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              color: "#000000",
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "95%",
          paddingBottom: "5px",
        }}
      >
        <a
          href={profileUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            background: "none",
            fontSize: "15px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            color: "#000",
            fontWeight: "500",
          }}
        >
          <img
            alt="avatar"
            src={user?.profile_picture_url}
            style={{ borderRadius: "9999px", width: "35px" }}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{user?.name}</div>
            <div style={{ fontSize: user?.name ? "13px" : "15px" }}>
              @{username}
            </div>
          </div>
        </a>
        <div>
          <a
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              background: "none",
              fontSize: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              color: "rgb(0, 149, 246)",
              padding: "4px",
              borderRadius: "5px",
              width: "100px",
            }}
          >
            Follow
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              strokeWidth="1.7"
              stroke="currentColor"
              fill="none"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#000",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          width: "100%",
          display: isLoading ? "block" : "grid",
          placeItems: "center",
          gridTemplateColumns: `repeat(${
            isLg || isMd ? "3" : "2"
          }, minmax(0, 1fr))`,
          gap: "5px",
          marginTop: "6px",
          paddingBottom: "5px",
          overflowY: "auto",
        }}
      >
        {isLoading ? (
          <SkeletonFeed isMd={isMd} isLg={isLg} />
        ) : posts ? (
          postEls
        ) : (
          <div style={{ height: "200vh" }}></div>
        )}
        <InstagramModal
          isOpen={isOpen}
          onClose={closeModal}
          isLg={isLg}
          isMd={isMd}
        >
          <div style={{ display: "block" }}>
            {posts &&
              posts.map((post, index) => {
                const currentCarousel = carousel.find(
                  (item) => item.postId === post.id
                );

                return (
                  <div key={index}>
                    <InstagramPost
                      post={post}
                      currentCarousel={currentCarousel}
                      id={`image-${index}`}
                      userData={user}
                    />
                  </div>
                );
              })}
          </div>
        </InstagramModal>
      </div>
    </div>
  );
};

export default InstagramFeed;
