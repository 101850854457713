import React, { useEffect, useRef, useState } from "react";
import MenuControls from "./MenuControls";
import ShareContainer from "./ShareContainer";
import ControlButton from "./ControlButton";
import { modalStyle, paragraphStyle, backdropFilterStyle } from "./Styles";
import InstagramFeed from "./InstagramFeed";
import VideoComponent from "./VideoComponent";
import { ClientData, FloorplanType } from "./Types";
import useGTM from "./useGTM";
import MenuButtons from "./MenuButtons";
import Tour3D from "./Tour3D";
import GetInTouch from "./GetInTouch";
import RadientSvg from "./RadientSvg.tsx";
import BookTour from "./BookTour.tsx";
import ListOfFloorplans from "./ListOfFloorplans.tsx";
import FloorplanDetails from "./FloorplanDetails.tsx";

type ModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  clientData?: ClientData;
};

export type PageData = {
  type?: "WELCOME" | "MODELUNIT" | "AMENITY";
  videoUrl?: string | undefined;
  title?: string;
  subtitle?: string;
  additionalInfo?: {
    title: string;
    features?: string[];
  }[];
  buttons?: {
    text: string;
    onClick?: () => void;
  }[];
};

const Modal = ({ isOpen, closeModal, clientData }: ModalProps) => {
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isShownMenu, setIsShownMenu] = useState(false);
  const [shared, setShared] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const fillerRef = useRef<HTMLDivElement>(null);
  const [playPromise, setPlayPromise] = useState<Promise<void> | undefined>();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isShownFloorplan, setIsShownFloorplan] = useState(false);
  const [isShownInstagram, setIsShownInstagram] = useState(false);
  const [isShownAmenities, setIsShownAmenities] = useState(false);

  const [isActiveMenuButtons, setIsActiveMenuButtons] = useState(false);
  const [isShownTour3d, setIsShownTour3d] = useState(false);
  const [isShownContact, setIsShownContact] = useState(false);
  const [isShownBookTour, setIsShownBookTour] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const pushEventToDataLayer = useGTM();

  const isLg = windowSize.width >= 1024;
  const isMd = windowSize.width >= 768 && windowSize.width < 1024;
  const isSm = windowSize.width < 768;

  const initialData: PageData = {
    type: clientData?.property.pages.find((el) => el.type === "WELCOME")?.type,
    videoUrl:
      clientData?.property.pages.find((el) => el.type === "WELCOME")?.video ||
      clientData?.property.coverVideo,
    title: clientData?.property.name,
    subtitle:
      clientData?.property.location.city +
      ", " +
      clientData?.property.location.state,
    buttons: [
      {
        text: "View Our Model Unit",
        onClick: () => {
          handleChangeContent(initialData, modelUnitData);
        },
      },
      {
        text: "Explore Amenities",
        onClick: () => {
          handleChangeContent(initialData, amenitiesData);
        },
      },
    ],
  };

  const modelUnitData: PageData = {
    type: clientData?.property.pages.find((el) => el.type === "MODELUNIT")
      ?.type,
    videoUrl: clientData?.property.pages.find((el) => el.type === "MODELUNIT")
      ?.video,
    title: "Model Units",
    subtitle: clientData?.property.pages.find((el) => el.type === "MODELUNIT")
      ?.name,
    // buttons: [
    //   {
    //     text: "Explore Amenities",
    //     onClick: () => {
    //       handleChangeContent(modelUnitData, amenitiesData);
    //     },
    //   },
    // ],
  };

  const amenitiesData: PageData = {
    type: clientData?.property.pages.find((el) => el.type === "AMENITY")?.type,
    videoUrl: clientData?.property.pages.find((el) => el.type === "AMENITY")
      ?.video,
    title: "Amenities",
    subtitle: clientData?.property.pages.find((el) => el.type === "AMENITY")
      ?.name,
    additionalInfo: clientData?.property.pages.find(
      (el) => el.type === "AMENITY"
    )?.additionalInfo,
    // buttons: [
    //   {
    //     text: "View Our Model Unit",
    //     onClick: () => {
    //       handleChangeContent(amenitiesData, modelUnitData);
    //     },
    //   },
    // ],
  };

  const [currentData, setCurrentData] = useState(initialData);
  const [history, setHistory] = useState<PageData[]>([]);

  const handleChangeContent = (previousData, nextData: PageData) => {
    setHistory((prev) => [...prev, previousData]);
    setCurrentData(nextData);
    if (videoRef.current?.paused) {
      setIsVideoPaused(false);
    }
  };

  const handleRevertContent = () => {
    if (history.length > 0) {
      const previousContent = history[history.length - 1];
      setHistory((prevHistory) => prevHistory.slice(0, -1));
      setCurrentData(previousContent);
    }
    if (videoRef.current?.paused) {
      setIsVideoPaused(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    document.addEventListener("fullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, []);

  //after first loop video is muted, when the view is changed, video should be unmuted
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = false;
    }
  }, [currentData]);

  useEffect(() => {
    const videoEl = videoRef.current;
    if (videoEl) {
      if (isOpen && !isActiveMenuButtons) {
        handlePlay();
        videoEl.currentTime = 0;
        videoEl.muted = false;
      } else {
        handlePause();
        videoEl.muted = true;
      }
    }
  }, [isOpen]);

  //mute after first loop of the video
  useEffect(() => {
    const videoEl = videoRef.current;

    const handleVideoEnd = () => {
      if (videoEl) {
        if (videoEl.currentTime >= videoEl.duration - 0.5) {
          videoEl.muted = true;
        }
      }
    };

    if (videoEl) {
      videoEl.addEventListener("timeupdate", handleVideoEnd);
    }

    return () => {
      if (videoEl) {
        videoEl.removeEventListener("timeupdate", handleVideoEnd);
      }
    };
  }, []);

  useEffect(() => {
    if (isActiveMenuButtons) {
      handlePause();
    } else {
      handlePlay();
    }
  }, [isActiveMenuButtons]);

  useEffect(() => {
    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      currentVideoRef.addEventListener("timeupdate", updateProgress);
    }
    return () => {
      if (currentVideoRef) {
        currentVideoRef.removeEventListener("timeupdate", updateProgress);
      }
    };
  }, []);

  const handlePause = () => {
    if (playPromise !== undefined) {
      playPromise
        .then((_) => videoRef.current?.pause())
        .catch((error) => console.log("autoplay prevented"));
    }

    setIsVideoPaused(true);
  };

  const handlePlay = () => {
    let playPromise = videoRef.current?.play();
    setPlayPromise(playPromise);
    setIsVideoPaused(false);
  };

  const handleToggleVideo = () => {
    videoRef.current?.paused ? handlePlay() : handlePause();
  };

  const handleFullScreen = () => {
    const el = modalRef.current;
    if (el) {
      if (el.requestFullscreen) {
        el.requestFullscreen();
      } else if ((el as any).mozRequestFullScreen) {
        (el as any).mozRequestFullScreen();
      } else if ((el as any).webkitRequestFullscreen) {
        (el as any).webkitRequestFullscreen();
      } else if ((el as any).msRequestFullscreen) {
        (el as any).msRequestFullscreen();
      }
    }
  };

  const handleExitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(
          `Error attempting to exit full-screen mode: ${err.message} (${err.name})`
        );
      });
    } else if ((document as any).mozFullScreenElement) {
      (document as any).mozCancelFullScreen();
    } else if ((document as any).webkitFullscreenElement) {
      (document as any).webkitExitFullscreen();
    } else if ((document as any).msFullscreenElement) {
      (document as any).msExitFullscreen();
    }
  };

  const handleFullScreenChange = () => {
    setIsFullscreen(!!document.fullscreenElement);
  };

  const toggleMute = () => {
    const currentVideoRef = videoRef.current;
    if (currentVideoRef) {
      currentVideoRef.muted = !currentVideoRef.muted;
      if (currentVideoRef.paused) {
        currentVideoRef.play();
        setIsVideoPaused(false);
      }
    }
  };

  const updateProgress = () => {
    if (videoRef.current) {
      const currentTime = videoRef.current.currentTime;
      const duration = videoRef.current.duration;
      const progressPercent = (currentTime / duration) * 100;
      setProgress(progressPercent);
    }
  };

  const toggleShowMenu = () => {
    setIsShownMenu(!isShownMenu);
    if (isShownInstagram) {
      setIsShownInstagram(false);
    }
    if (isShownAmenities) {
      setIsShownAmenities(false);
    }
  };

  const toggleShared = () => {
    setShared(!shared);
  };

  const toggleShowFloorplan = (value: boolean) => {
    // only fire when it renders floorplan
    if (!isShownFloorplan && value)
      pushEventToDataLayer(clientData?.id + " show_all_floorplans", {
        event_category: "button",
        event_action: "click",
        event_label: "floorplan_button",
      });
    setIsShownFloorplan(value);
  };

  const toggleShowInstagram = () => {
    if (!isShownInstagram) {
      pushEventToDataLayer(clientData?.id + " show_instagram", {
        event_category: "button",
        event_action: "click",
        event_label: "instagram_button",
      });
    }

    const videoEl = videoRef.current;
    if (videoEl) {
      if (!isShownInstagram) {
        handlePause();
        videoEl.muted = true;
      } else {
        if (!isActiveMenuButtons) {
          handlePlay();
          videoEl.muted = false;
        }
      }
    }
    setIsShownInstagram(!isShownInstagram);
    if (isShownMenu) {
      setIsShownMenu(false);
    }
    if (isShownAmenities) {
      setIsShownAmenities(false);
    }
  };

  const toggleShowAmenities = () => {
    setIsShownAmenities(!isShownAmenities);
    if (isShownMenu) {
      setIsShownMenu(false);
    }
    if (isShownInstagram) {
      setIsShownInstagram(false);
    }
  };

  const toggleShowTour3d = (value: boolean) => {
    setIsShownTour3d(value);
  };

  const toggleShowGetInTouch = (value: boolean) => {
    setIsShownContact(value);
  };

  const toggleShowBookTour = (value: boolean) => {
    setIsShownBookTour(value);
  };

  const handleFillerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (fillerRef.current && videoRef.current) {
      const fillerRect = fillerRef.current.getBoundingClientRect();
      const clickPosition = event.clientX - fillerRect.left;
      const newTime =
        (clickPosition / fillerRect.width) * videoRef.current.duration;
      videoRef.current.currentTime = newTime;
      setProgress((newTime / videoRef.current.duration) * 100);
    }
  };

  const handleCloseModal = () => {
    handleExitFullScreen();
    closeModal();
  };

  const handleIsActiveMenuButtons = (value: boolean) => {
    setIsActiveMenuButtons(value);
    setIsShownFloorplan(false);
    setIsShownTour3d(false);
    setIsShownBookTour(false);
    setIsShownContact(false);
  };

  //floorplans
  const [activeComponent, setActiveComponent] = useState<
    "listFloorplans" | "floorplanDetails" | "bookTour" | "askQuestion"
  >("listFloorplans");
  const [detailsData, setDetailsData] = useState<FloorplanType | null>(null);

  const [bedFilter, setBedFilter] = useState(0);
  const [bathFilter, setBathFilter] = useState(0);
  const [priceFilter, setPriceFilter] = useState<number[]>([1100, 2550]);

  const scrollPositionRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleBedFilter = (value: number) => setBedFilter(value);
  const handleBathFilter = (value: number) => setBathFilter(value);
  const handlePriceFilter = (value: number[]) => setPriceFilter(value);

  const toggleToListFloorplans = () => setActiveComponent("listFloorplans");
  const toggleToFloorplanDetails = () => setActiveComponent("floorplanDetails");
  const toggleToBookTour = () => setActiveComponent("bookTour");
  const toggleToAskQuestion = () => setActiveComponent("askQuestion");

  const showDetails = (data: FloorplanType) => {
    setDetailsData(data);
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop;
    }
    toggleToFloorplanDetails();
  };

  useEffect(() => {
    // Restore scroll position when returning to the list view
    if (activeComponent === "listFloorplans" && containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [activeComponent]);

  //if it's in subcomponents of floorplans component, toggle between them
  //if it's in floorplans or any other component in menu, toggle to main menu
  const handleGoBack = () => {
    if (isShownFloorplan && activeComponent !== "listFloorplans") {
      if (activeComponent === "bookTour" || activeComponent === "askQuestion") {
        toggleToFloorplanDetails();
      } else toggleToListFloorplans();
    } else {
      handleIsActiveMenuButtons(false);
    }
  };

  return (
    <div
      ref={modalRef}
      id="modal"
      style={{
        ...modalStyle,
        ...{
          height: !isLg ? "95%" : "90%",
          left:
            clientData?.ui.position === "left"
              ? !isLg
                ? "20px"
                : "35px"
              : "unset",
          right:
            clientData?.ui.position === "right"
              ? !isLg
                ? "20px"
                : "35px"
              : "unset",
          width: !isLg ? "85%" : "65%",
          pointerEvents: !isOpen ? "none" : "all",
          zIndex: !isOpen ? "-10" : "auto",
          opacity: !isOpen ? "0" : "1",
          transform: !isOpen ? "scale(0.3)" : "scale(1)",
          transformOrigin: `${clientData?.ui.position} bottom`,
          backgroundColor: "rgb(var(--w-secondary-color))",
          transition: "all 0.2s linear 0s",
        },
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          zIndex: 20,
          position: "absolute",
          top: 0,
          color: "#FFFFFF",
          pointerEvents: "none",
          width: !isLg ? "100%" : isActiveMenuButtons ? "100%" : "65%",
        }}
      >
        {/* filler for video  */}
        <div
          ref={fillerRef}
          onClick={handleFillerClick}
          style={{
            width: "100%",
            height: "0.5rem",
            pointerEvents: "auto",
            transition: "height 0.2s",
            cursor: "pointer",
            display: isActiveMenuButtons ? "none" : "block",
          }}
          onMouseEnter={() => {
            if (fillerRef.current) {
              fillerRef.current.style.height = "1rem";
            }
          }}
          onMouseLeave={() => {
            if (fillerRef.current) {
              fillerRef.current.style.height = "0.5rem";
            }
          }}
        >
          <div
            style={{
              borderTopRightRadius: "0.375rem",
              borderBottomRightRadius: "0.375rem",
              height: "100%",
              pointerEvents: "none",
              width: `${progress}%`,
              backgroundColor: "var(--w-primary-color)",
            }}
          ></div>
        </div>

        {/* menu for video controls */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            pointerEvents: "none",
          }}
        >
          <MenuControls
            isLg={isLg}
            isMd={isMd}
            isFullScreen={isFullscreen}
            handleFullScreen={handleFullScreen}
            handleExitFullScreen={handleExitFullScreen}
            toggleMute={toggleMute}
            isActiveMenuButtons={isActiveMenuButtons}
            handleIsActiveMenuButtons={handleIsActiveMenuButtons}
            goBack={
              isActiveMenuButtons
                ? handleGoBack
                : currentData.type === "WELCOME"
                ? undefined
                : handleRevertContent
            }
            muted={videoRef?.current?.muted}
            closeModal={handleCloseModal}
            toggleShared={toggleShared}
            toggleShowInstagram={toggleShowInstagram}
            toggleShowMenu={toggleShowMenu}
          />
        </div>
      </div>
      {/* left half - components */}
      <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
        <div
          style={{
            width: !isLg ? "100%" : isActiveMenuButtons ? "100%" : "65%",
            height: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            backgroundColor: "#fff",
            position: "relative",
          }}
        >
          {isShownFloorplan ? (
            // <Floorplans
            //   clientData={clientData}
            //   isSm={isSm}
            //   isMd={isMd}
            //   isLg={isLg}
            // />
            <div
              style={{
                overflowY: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                height: "100%",
                maxHeight: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "50px",
                  padding: "7px 15px",
                  background: "#fff",
                  borderBottom: "1px solid var(--w-tertiary-color)",
                  position: "sticky",
                  top: 0,
                  left: 0,
                  right: 0,
                }}
              ></div>
              <div
                style={{
                  overflowY: "auto",
                  maxHeight: "100vh",
                  marginBottom: isSm || isMd ? "60px" : "unset",
                }}
                ref={containerRef}
              >
                <ListOfFloorplans
                  floorplans={clientData?.property.floorplans.sort(
                    (a: FloorplanType, b: FloorplanType) =>
                      a.price.min - b.price.min
                  )}
                  showDetails={showDetails}
                  activeComponent={activeComponent}
                  bedFilter={bedFilter}
                  bathFilter={bathFilter}
                  priceFilter={priceFilter}
                  isSm={isSm}
                  isMd={isMd}
                  scrollRef={containerRef}
                  handlePriceFilter={handlePriceFilter}
                  handleBathFilter={handleBathFilter}
                  handleBedFilter={handleBedFilter}
                />
                <FloorplanDetails
                  data={detailsData}
                  activeComponent={activeComponent}
                  toggleToBookTour={toggleToBookTour}
                  toggleToAskQuestion={toggleToAskQuestion}
                  clientData={clientData}
                  isLg={isLg}
                  isMd={isMd}
                />
              </div>
            </div>
          ) : null}

          {isShownTour3d ? (
            <Tour3D clientData={clientData} isLg={isLg} isSm={isSm} />
          ) : null}

          {isShownContact ? (
            <GetInTouch clientData={clientData} isLg={isLg} />
          ) : null}
          {isShownBookTour ? (
            <>
              <div
                style={{
                  // overflowY: "auto",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  maxHeight: "100%",
                  paddingBottom: !isLg ? "64px" : "unset",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "50px",
                    padding: "7px 15px",
                    background: "#fff",
                    borderBottom: "1px solid var(--w-tertiary-color)",
                    position: "sticky",
                    top: 0,
                    left: 0,
                    right: 0,
                  }}
                ></div>
                <BookTour
                  calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
                  isLg={isLg}
                  isMd={isMd}
                />
              </div>
            </>
          ) : null}

          <VideoComponent
            isLg={isLg}
            isSm={isSm}
            isShownMenu={isShownMenu}
            currentData={currentData}
            handleToggleVideo={handleToggleVideo}
            videoRef={videoRef}
            isVideoPaused={isVideoPaused}
            isShownInstagram={isShownInstagram}
            isActiveMenuButtons={isActiveMenuButtons}
            toggleShowAmenities={toggleShowAmenities}
            isShownAmenities={isShownAmenities}
          />

          {/* instagram  */}
          <div
            style={{
              // transform: isShownInstagram
              //   ? "translateY(0%)"
              //   : "translateY(100%)",
              opacity: isShownInstagram ? "1" : "0",
              background: "rgb(var(--w-secondary-color), 0.9)" ?? "#FFFFFF",
              position: "absolute",
              zIndex: isShownInstagram ? 20 : -1,
              width: "100%",
              height: "80%",
              borderTopLeftRadius: "0.75rem",
              borderTopRightRadius: "0.75rem",
              bottom: !isLg ? "64px" : 0,
              left: 0,
              // paddingBottom: !isLg ? 0 : "2rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              transition: "all 0.2s ease",
              boxShadow: "rgba(0, 0, 0, 0.3) 0px 3px 20px",
            }}
          >
            {isShownInstagram && (
              <InstagramFeed
                clientData={clientData}
                toggleShowInstagram={toggleShowInstagram}
                isMd={isMd}
                isSm={isSm}
                isLg={isLg}
                isActiveMenuButtons={isActiveMenuButtons}
              />
            )}
          </div>

          {/* share option */}
          {/* <div
            style={{
              // transform: shared ? "translateY(0%)" : "translateY(100%)",
              opacity: shared ? "1" : "0",
              background: "var(--w-secondary-color)" || "#FFFFFF",
              position: "absolute",
              zIndex: shared ? 50 : -1,
              width: "100%",
              borderTopLeftRadius: "0.75rem",
              borderTopRightRadius: "0.75rem",
              bottom: 0,
              paddingBottom: !isLg ? "80px" : "2rem",
              left: 0,
              paddingTop: "0.25rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              color: clientData?.ui.colors.text.heading ?? "#000000",
              transition: "all 0.4s ease",
              borderTop:
                isShownInstagram || (!isLg && isShownMenu)
                  ? `1px solid var(--w-tertiary-color)`
                  : "",
            }}
          >
            <ShareContainer toggleShow={toggleShared} />
          </div> */}
        </div>

        {/* welcome and buttons */}
        <div
          style={{
            width: isLg
              ? !isActiveMenuButtons
                ? "35%"
                : isActiveMenuButtons
                ? "180px"
                : "100%"
              : "100%",
            zIndex:
              isLg && !isActiveMenuButtons ? "auto" : isShownMenu ? "30" : "-1",
            pointerEvents: "none",
            bottom: isLg && isActiveMenuButtons ? "unset" : 0,
            top: isLg && isActiveMenuButtons ? "55px" : "unset",
            right: isLg && isActiveMenuButtons ? "50px" : "unset",
            paddingBottom: isSm || isMd ? "64px" : 0,
            display: "flex",
            opacity:
              isLg && !isActiveMenuButtons ? "1" : isShownMenu ? "1" : "0",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: isLg && !isActiveMenuButtons ? "static" : "absolute",
            borderLeft:
              isLg && !isActiveMenuButtons
                ? `1px solid var(--w-tertiary-color)`
                : "",
            background:
              isLg && isActiveMenuButtons
                ? "#fff"
                : "rgba(var(--w-secondary-color), 0.8)",
            borderTopLeftRadius: isLg && !isActiveMenuButtons ? "0" : "0.75rem",
            borderTopRightRadius:
              isLg && !isActiveMenuButtons ? "0" : "0.75rem",
            borderBottomRightRadius:
              isLg && isActiveMenuButtons ? "0.75rem" : "0",
            borderBottomLeftRadius:
              isLg && isActiveMenuButtons ? "0.75rem" : "0",
            transition: "all 0.3s ease",
            boxShadow:
              isLg && isActiveMenuButtons
                ? "rgba(0, 0, 0, 0.4) 0px 5px 10px"
                : !isLg
                ? "rgba(0, 0, 0, 0.5) 0px 3px 20px"
                : "none",
          }}
        >
          <div
            style={{
              ...(!isLg && isActiveMenuButtons && backdropFilterStyle),
              height: "100%",
              width: isLg && !isActiveMenuButtons ? "35%" : "100%",
              position: "absolute",
              top: 0,
              borderTopLeftRadius: !isLg ? "0.75rem" : 0,
              borderTopRightRadius: !isLg ? "0.75rem" : 0,
              borderBottomLeftRadius: isLg ? "0.75rem" : 0,
              borderBottomRightRadius: isLg ? "0.75rem" : 0,
              transition: "all 0.4s ease",
            }}
          />
          <div
            style={{
              height: "100%",
              width: "100%",
              pointerEvents: "auto",
              borderTopLeftRadius: !isLg ? "0.75rem" : "0",
              borderTopRightRadius: !isLg ? "0.75rem" : "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                alignItems: "center",
                color: "#6B7280",
                justifyContent: "flex-end",
              }}
            >
              {(isSm || isMd || (isLg && !isActiveMenuButtons)) && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    paddingTop: "1.25rem",
                    paddingRight: isActiveMenuButtons ? "0.5rem" : "1.25rem",
                  }}
                >
                  {/* share button on menu */}
                  {/*
                <ControlButton
                  style={{
                    border: "none",
                    background: "none",
                    padding: "0.625rem",
                    display: "flex",
                    transition: "background-color 0.15s ease",
                    cursor: "pointer",
                    zIndex: 2,
                  }}
                  onClick={toggleShared}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    style={{
                      width: "24px",
                      height: "24px",
                      color: "#000",
                    }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z"
                    ></path>
                  </svg>
                </ControlButton> */}
                  {/* close widget button */}
                  <ControlButton
                    style={{
                      border: "none",
                      background: "none",
                      padding: "0.625rem",
                      display: "flex",
                      transition: "background-color 0.15s ease",
                      cursor: "pointer",
                      zIndex: 2,
                    }}
                    onClick={
                      isLg && !isActiveMenuButtons
                        ? handleCloseModal
                        : toggleShowMenu
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth="1.7"
                      stroke="currentColor"
                      style={{
                        width: "24px",
                        height: "24px",
                        color: "#000",
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      ></path>
                    </svg>
                  </ControlButton>
                </div>
              )}
            </div>
            {isLg && !isActiveMenuButtons && (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <RadientSvg />
              </div>
            )}
            {/* welcome part */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                height: "100%",
                paddingBottom: isLg ? "0.75rem" : "1.5rem",
                justifyContent: "center",
                paddingLeft: !isLg ? "10%" : "0.5rem",
                paddingRight: !isLg ? "10%" : "0.5rem",
              }}
            >
              <>
                <div
                  style={{
                    color: clientData?.ui.colors.text.heading ?? "#000",
                    fontSize: isLg ? "30px" : "22px",
                    textAlign: "center",
                    fontWeight: 600,
                    display:
                      isSm || isMd || (isLg && !isActiveMenuButtons)
                        ? "flex"
                        : "none",
                    flexDirection: "column",
                    zIndex: 2,
                  }}
                >
                  {currentData.type === "WELCOME" && (
                    <span
                      style={{
                        fontWeight: 300,
                        fontSize: isLg ? "18px" : "16px",
                        paddingBottom: "10px",
                      }}
                    >
                      Welcome to
                    </span>
                  )}

                  {currentData.title}
                </div>
                <div
                  style={{
                    fontSize: "14px",
                    color: "#6B7280",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    zIndex: 2,
                    display:
                      isSm || isMd || (isLg && !isActiveMenuButtons)
                        ? "flex"
                        : "none",
                  }}
                >
                  Where to next?
                </div>
              </>

              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
                  width: "100%",
                  maxWidth: "20rem",
                  marginTop: "10px",
                  gap: "0.5rem",
                }}
              >
                <MenuButtons
                  isShownFloorplan={isShownFloorplan}
                  isShownTour3d={isShownTour3d}
                  isShownContact={isShownContact}
                  isShownBookTour={isShownBookTour}
                  toggleShowFloorplan={toggleShowFloorplan}
                  toggleShowTour3d={toggleShowTour3d}
                  toggleShowGetInTouch={toggleShowGetInTouch}
                  toggleShowBookTour={toggleShowBookTour}
                  isActiveMenuButtons={isActiveMenuButtons}
                  setIsActiveMenuButtons={setIsActiveMenuButtons}
                  setIsShownMenu={setIsShownMenu}
                  setActiveFloorplanComponent={setActiveComponent}
                  isLg={isLg}
                />
                {/* {currentData.type !== "WELCOME" &&
                    ((isLg && !isActiveMenuButtons) || !isLg) && (
                      <Button
                        text={"Restart Tour"}
                        handleClick={() => {
                          setCurrentData(initialData);
                          setHistory([]);
                          setIsActiveMenuButtons(false);
                          setIsVideoPaused(false);
                          setIsShownMenu(false);
                        }}
                        first={false}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            style={{ marginTop: "3px" }}
                          >
                            <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8"></path>
                            <path d="M3 3v5h5"></path>
                          </svg>
                        }
                      />
                    )} */}
              </div>
            </div>
          </div>
          {/* footer if needed */}
          <div
            style={{
              display: isLg && !isActiveMenuButtons ? "flex" : "none",
              // cursor: "pointer",
              flexDirection: "row",
              alignItems: "center",
              color: "#ffffff",
              width: "100%",
              position: "relative",
              zIndex: 20,
              justifyContent: "center",
            }}
          >
            <div
              style={{
                pointerEvents: "auto",
                display: "flex",
                height: "60px",
                bottom: 0,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                position: "absolute",
                transition: "opacity 0.2s ease",
                borderTop: "1px solid",
                borderTopColor: "var(--w-tertiary-color)" ?? "#000000",
                width: "100%",
              }}
            >
              <div
                style={{
                  fontSize: "13px",
                  fontWeight: "300",
                  color: "#000000",
                  // paddingTop: "14px",
                  // paddingBottom: "14px",
                  whiteSpace: "nowrap",
                  paddingRight: "5px",
                }}
              >
                Powered by
              </div>

              <img
                src={`${process.env.REACT_APP_API_URL}/assets/smp-logo.png`}
                alt="smp logo"
                style={{
                  height: "45px",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* show main menu on small screens */}

      <div
        onClick={toggleShowMenu}
        style={{
          background: "rgb(var(--w-secondary-color))" ?? "#ffffff",
          borderTop: !isLg ? "1px solid var(--w-tertiary-color)" : "none",
          display: "flex",
          cursor: "pointer",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          transition: "opacity 0.2s ease",
          minHeight: "64px",
          height: "64px",
          zIndex: !isLg ? 50 : -1,
          position: "absolute",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          viewBox="0 0 20 20"
          style={{
            width: "1.5rem",
            height: "1.5rem",
            color: "#9CA3AF",
          }}
        >
          <path
            fillRule="evenodd"
            d="M1.46447 18.5355C2.92893 20 5.28595 20 10 20c4.714 0 7.0711 0 8.5355-1.4645C20 17.0711 20 14.714 20 10c0-4.71405 0-7.07107-1.4645-8.53553C17.0711 0 14.714 0 10 0 5.28595 0 2.92893 0 1.46447 1.46447 0 2.92893 0 5.28595 0 10c0 4.714 0 7.0711 1.46447 8.5355ZM16.75 14c0 .4142-.3358.75-.75.75H4c-.41421 0-.75-.3358-.75-.75s.33579-.75.75-.75h12c.4142 0 .75.3358.75.75ZM16 10.75c.4142 0 .75-.3358.75-.75 0-.41421-.3358-.75-.75-.75H4c-.41421 0-.75.33579-.75.75 0 .4142.33579.75.75.75h12ZM16.75 6c0 .41421-.3358.75-.75.75H4c-.41421 0-.75-.33579-.75-.75s.33579-.75.75-.75h12c.4142 0 .75.33579.75.75Z"
            clipRule="evenodd"
          ></path>
        </svg>
        <p
          style={{
            ...{
              fontSize: "0.875rem",
              fontWeight: 500,
              color: "#9CA3AF",
              marginLeft: "0.25rem",
              whiteSpace: "nowrap",
            },
            ...paragraphStyle,
          }}
        >
          {isShownMenu ? "Hide Main Menu" : "Show Main Menu"}
        </p>
      </div>
    </div>
  );
};

export default Modal;
