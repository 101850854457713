import React, { useEffect, useState } from "react";
import Button from "./Button";

const MenuButtons = ({
  isShownFloorplan,
  isShownTour3d,
  isShownContact,
  isShownBookTour,
  toggleShowFloorplan,
  toggleShowTour3d,
  toggleShowGetInTouch,
  toggleShowBookTour,
  isActiveMenuButtons,
  setIsActiveMenuButtons,
  setIsShownMenu,
  setActiveFloorplanComponent,
  isLg,
}: {
  isShownFloorplan: boolean;
  isShownTour3d: boolean;
  isShownContact: boolean;
  isShownBookTour: boolean;
  toggleShowFloorplan: (value: boolean) => void;
  toggleShowTour3d: (value: boolean) => void;
  toggleShowGetInTouch: (value: boolean) => void;
  toggleShowBookTour: (value: boolean) => void;
  isActiveMenuButtons: boolean;
  setIsActiveMenuButtons: React.Dispatch<React.SetStateAction<boolean>>;
  setIsShownMenu: React.Dispatch<React.SetStateAction<boolean>>;
  setActiveFloorplanComponent: React.Dispatch<
    React.SetStateAction<
      "listFloorplans" | "floorplanDetails" | "bookTour" | "askQuestion"
    >
  >;
  isLg: boolean;
}) => {
  const [activeButton, setActiveButton] = useState<string | null>(null);

  useEffect(() => {
    if (!isActiveMenuButtons) {
      setActiveButton(null);
    }
  }, [isActiveMenuButtons]);

  const toggleActiveButton = (value: string) => {
    if (activeButton === value) {
      setActiveButton(null);
      setIsActiveMenuButtons(false);
      setIsShownMenu(true);
    } else {
      setActiveButton(value);
      setIsActiveMenuButtons(true);
      setIsShownMenu(false);
    }
  };
  return (
    <div
      style={{
        display: "grid",
        gridTemplateRows: "repeat(1, minmax(0px, 1fr))",
        gap: isLg && isActiveMenuButtons ? "0.25rem" : "0.5rem",
      }}
    >
      <Button
        text={"Check out Floor Plans"}
        isActive={activeButton === "floorplans"}
        first={true}
        isBurgerMenu={isLg && isActiveMenuButtons}
        handleClick={() => {
          toggleShowFloorplan(!isShownFloorplan);
          toggleShowGetInTouch(false);
          toggleShowTour3d(false);
          toggleShowBookTour(false);
          toggleActiveButton("floorplans");
          setActiveFloorplanComponent("listFloorplans");
        }}
      />
      <div
        style={{
          display: "grid",
          gridTemplateColumns: `repeat(${
            !isLg || (isLg && !isActiveMenuButtons) ? "2" : "1"
          }, minmax(0px, 1fr))`,
          gap: isLg && isActiveMenuButtons ? "0.25rem" : "0.5rem",
        }}
      >
        <Button
          text={"Take a 3D Tour"}
          isActive={activeButton === "tour"}
          first={false}
          isBurgerMenu={isLg && isActiveMenuButtons}
          handleClick={() => {
            toggleShowTour3d(!isShownTour3d);
            toggleShowGetInTouch(false);
            toggleShowFloorplan(false);
            toggleShowBookTour(false);
            toggleActiveButton("tour");
            setActiveFloorplanComponent("listFloorplans");
          }}
        />
        <Button
          text={"Book a Tour"}
          first={false}
          isActive={activeButton === "book-tour"}
          isBurgerMenu={isLg && isActiveMenuButtons}
          handleClick={() => {
            toggleShowBookTour(!isShownBookTour);
            toggleShowGetInTouch(false);
            toggleShowTour3d(false);
            toggleShowFloorplan(false);
            toggleActiveButton("askQuestion");
            toggleActiveButton("book-tour");
            setActiveFloorplanComponent("listFloorplans");
          }}
        />
      </div>
      <Button
        text={"Get in Touch"}
        isActive={activeButton === "contact"}
        first={false}
        isBurgerMenu={isLg && isActiveMenuButtons}
        handleClick={() => {
          toggleShowGetInTouch(!isShownContact);
          toggleShowBookTour(false);
          toggleShowTour3d(false);
          toggleShowFloorplan(false);
          toggleActiveButton("contact");
          setActiveFloorplanComponent("listFloorplans");
        }}
      />
    </div>
  );
};

export default MenuButtons;
