import React, { useEffect, useRef, useState } from "react";
import { ClientData, FloorplanType } from "./Types";
import Tour3D from "./Tour3D";
import GetInTouch from "./GetInTouch";
import BookTour from "./BookTour.tsx";
import ListOfFloorplans from "./ListOfFloorplans.tsx";
import FloorplanDetails from "./FloorplanDetails.tsx";
import { useLocation, useNavigate } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import WidgetLayout from "./WidgetLayout.tsx";
import AskQuestion from "./AskQuestion.tsx";
import PageNotFound from "./404.tsx";

export type PageData = {
  type?: "WELCOME" | "MODELUNIT" | "AMENITY";
  videoUrl?: string | undefined;
  title?: string;
  subtitle?: string;
  additionalInfo?: {
    title: string;
    features?: string[];
  }[];
  buttons?: {
    text: string;
    onClick?: () => void;
  }[];
};

const VideoWidget = ({ clientData }: { clientData?: ClientData }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const isLg = windowSize.width >= 1024;
  const isMd = windowSize.width >= 768 && windowSize.width < 1024;
  const isSm = windowSize.width < 768;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  //floorplans
  const [detailsData, setDetailsData] = useState<FloorplanType | null>(null);

  const [bedFilter, setBedFilter] = useState(0);
  const [bathFilter, setBathFilter] = useState(0);
  const [priceFilter, setPriceFilter] = useState<number[]>([1100, 2550]);

  const scrollPositionRef = useRef<number>(0);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleBedFilter = (value: number) => setBedFilter(value);
  const handleBathFilter = (value: number) => setBathFilter(value);
  const handlePriceFilter = (value: number[]) => setPriceFilter(value);

  const showDetails = (data: FloorplanType) => {
    setDetailsData(data);
    if (containerRef.current) {
      scrollPositionRef.current = containerRef.current.scrollTop;
    }
    navigate(`/video/floorplans/floorplan/${data.name}`);
  };

  const handleSetData = (id: string) => {
    const floorplan = clientData?.property.floorplans.find(
      (el) => el.name === id
    );
    if (floorplan) setDetailsData(floorplan);
  };

  useEffect(() => {
    // Restore scroll position when returning to the list view
    if (location.pathname === "/video/floorplans" && containerRef.current) {
      containerRef.current.scrollTop = scrollPositionRef.current;
    }
  }, [location]);

  if (!clientData) {
    return null;
  }

  return (
    <Routes>
      <Route path="/" element={<WidgetLayout clientData={clientData} />} />

      <Route
        path="/floorplans"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <ListOfFloorplans
              floorplans={clientData?.property.floorplans.sort(
                (a: FloorplanType, b: FloorplanType) =>
                  a.price.min - b.price.min
              )}
              showDetails={showDetails}
              bedFilter={bedFilter}
              bathFilter={bathFilter}
              priceFilter={priceFilter}
              isSm={isSm}
              isMd={isMd}
              scrollRef={containerRef}
              handlePriceFilter={handlePriceFilter}
              handleBathFilter={handleBathFilter}
              handleBedFilter={handleBedFilter}
              clientId={clientData?.id}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/floorplans/floorplan/:id"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <FloorplanDetails
              data={detailsData}
              setData={handleSetData}
              clientId={clientData.id}
              bookTourLink={clientData.ui.videoWidget.bookTourLink}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/floorplans/floorplan/:id/book"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            {clientData.ui.videoWidget.bookTourLink ? (
              <></>
            ) : (
              <BookTour
                apartmentName={detailsData?.name}
                calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
                isLg={isLg}
                isMd={isMd}
                setData={handleSetData}
              />
            )}
          </WidgetLayout>
        }
      />
      <Route
        path="/floorplans/floorplan/:id/ask-question"
        element={
          <WidgetLayout clientData={clientData} floorplanId={detailsData?.name}>
            <AskQuestion
              clientData={clientData}
              apartmentName={detailsData?.name}
              setData={handleSetData}
            />
          </WidgetLayout>
        }
      />
      <Route
        path="/book-tour"
        element={
          <WidgetLayout clientData={clientData}>
            {clientData.ui.videoWidget.bookTourLink ? (
              <></>
            ) : (
              <BookTour
                calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
                isLg={isLg}
                isMd={isMd}
              />
            )}
          </WidgetLayout>
        }
      />
      <Route
        path="/get-in-touch"
        element={
          <WidgetLayout clientData={clientData}>
            <GetInTouch clientData={clientData} isLg={isLg} />
          </WidgetLayout>
        }
      />
      <Route
        path="/tour-3d"
        element={
          <WidgetLayout clientData={clientData}>
            <Tour3D clientData={clientData} isLg={isLg} isSm={isSm} />
          </WidgetLayout>
        }
      />
      <Route path="*" element={<PageNotFound clientData={clientData} />} />
    </Routes>
  );
};

export default VideoWidget;
