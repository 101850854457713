import React, { useEffect, useState } from "react";
import {
  widgetStyle,
  shapeWidgetStyle,
  hoverStyle,
  kf1,
  kf2,
  kf3,
  kf4,
  kf5,
  kf6,
} from "./Styles";
import CircleComponent from "./CircleComponent";
import Modal from "./Modal";
import ChatWidget from "./ChatWidget";
import { ClientData } from "./Types";
import useGTM from "./useGTM";
import axios from "axios";

export const Widget = ({ clientId }: { clientId?: string | number }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [clientData, setClientData] = useState<ClientData>();
  const pushEventToDataLayer = useGTM();

  const openModal = () => {
    setIsOpen(true);
    pushEventToDataLayer(clientId + " widget_opened", {
      event_category: "button",
      event_action: "click",
      event_label: "circle_button",
    });
  };

  const closeModal = () => {
    setIsOpen(false);
    pushEventToDataLayer(clientId + " widget_closed", {
      event_category: "button",
      event_action: "click",
      event_label: "circle_button",
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      let url = `${process.env.REACT_APP_API_URL}/client/${clientId}`;
      try {
        const response = await axios.get(url);
        setClientData(response.data);
      } catch (error) {
        console.error(
          "Unexpected error while fetching instagram data. Error:",
          error.message
        );
      }
    };

    fetchData();
  }, [clientId]);

  //loading styles
  useEffect(() => {
    if (clientData) {
      const cssContent = `
      :root {
        --w-primary-color: ${clientData.ui.colors.background.primary};
        --w-secondary-color: ${clientData.ui.colors.background.secondary};
        --w-tertiary-color: ${clientData.ui.colors.background.tertiary};
      }

      #smp-widget *::-webkit-scrollbar {
        width: 0; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
      }

      #smp-widget * {
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 400;
        font-style: normal;
        box-sizing: border-box;
      }

      #w-ig-modal * {
        font-family: 'Proxima Nova', sans-serif;
        font-weight: 400;
        font-style: normal;
        box-sizing: border-box;
      }


      #w-amenity-list li {
        list-style-position: outside;
        font-weight: 300;
        padding: 2px 1px;
      }

      .w-loader {
        position: absolute;
        width: 100%;
        height: 100%;
        background: none;
        animation: widgetloader 4s linear infinite;
        webkit-clip-path: polygon(48% 8%, 50% 7.83%, 52% 8%, 95% 13%, 96% 13.3%, 98% 14%, 99% 15%, 99.8% 17%, 100% 18%, 100% 82%, 99.8% 83%, 99% 85%, 98% 86%, 96% 86.7%, 95% 87%, 52% 92%, 50% 92.17%, 48% 92%, 5% 87%, 4% 86.7%, 2% 86%, 1% 85%, 0.2% 83%, 0 82%, 0 18%, 0.2% 17%, 1% 15%, 2% 14%, 4% 13.3%, 5% 13%);
        clip-path: polygon(48% 8%, 50% 7.83%, 52% 8%, 95% 13%, 96% 13.3%, 98% 14%, 99% 15%, 99.8% 17%, 100% 18%, 100% 82%, 99.8% 83%, 99% 85%, 98% 86%, 96% 86.7%, 95% 87%, 52% 92%, 50% 92.17%, 48% 92%, 5% 87%, 4% 86.7%, 2% 86%, 1% 85%, 0.2% 83%, 0 82%, 0 18%, 0.2% 17%, 1% 15%, 2% 14%, 4% 13.3%, 5% 13%);
      }
      
      .w-loader:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--w-primary-color);
        clip-path: inherit;
      }

      .calendly-inline-widget, .simplebar-content-wrapper {
        overflow: hidden !important;
      }

      ${kf1}
      ${kf2}
      ${kf3}
      ${kf4}
      ${kf5}
      ${kf6}
    `;

      injectStyle(cssContent);
    }
  }, [clientData]);

  return (
    <div id="smp-widget">
      {clientData?.ui.videoWidget.isActive && (
        <>
          <div
            style={{
              ...widgetStyle,
              ...(isOpen && { opacity: "0" }),
              ...{
                filter: "drop-shadow(0 0 10px rgba(0, 0, 0, 0.3)",
                animation: "ping 1s cubic-bezier(0, 0, 0.2, 1) 5",
                left: clientData?.ui.position === "left" ? "35px" : "unset",
                right: clientData?.ui.position === "right" ? "35px" : "unset",
              },
            }}
          >
            <div
              style={{
                ...widgetStyle,
                ...shapeWidgetStyle,
                ...{
                  backgroundColor: "var(--w-primary-color)",
                  left: clientData?.ui.position === "left" ? "35px" : "unset",
                  right: clientData?.ui.position === "right" ? "35px" : "unset",
                  position: "static",
                },
              }}
            ></div>
          </div>
          <div
            style={{
              ...widgetStyle,
              ...shapeWidgetStyle,
              ...(hovered && hoverStyle),
              ...(isOpen && { opacity: "0" }),
              ...{
                backgroundColor: "#fff",
                animation: "border-turns-primary-from-white 0.5s 3.8s both",
                border: "none",
                left: clientData?.ui.position === "left" ? "35px" : "unset",
                right: clientData?.ui.position === "right" ? "35px" : "unset",
              },
            }}
            onClick={openModal}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            {/* gradients */}
            <div
              className="w-gradients"
              style={{
                width: "92%",
                height: "92%",
                position: "absolute",
                overflow: "hidden",
                animation: "wh-returns-original 0.5s 4s both",
              }}
            >
              <div
                style={{
                  ...shapeWidgetStyle,
                  ...{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 1))",
                    opacity: 0.8,
                    zIndex: 30,
                    top: 0,
                    left: 0,
                  },
                }}
              />
              <div
                style={{
                  ...shapeWidgetStyle,
                  ...{
                    position: "absolute",
                    zIndex: "9999",
                    width: "100%",
                    height: "100%",
                    backgroundImage: hovered
                      ? `linear-gradient(to bottom, var(--w-primary-color), color-mix(in srgb, var(--w-primary-color) 10%, transparent) 70%)`
                      : "",
                    opacity: 0.8,
                    transition: "all 0.3s ease-in-out 0.1s",
                  },
                }}
              />
            </div>

            <CircleComponent
              videoUrl={clientData?.property.coverVideo}
              posterUrl={clientData?.property.coverImage}
            />

            <div className="w-loader"></div>

            {/* text */}
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                color: "#FFFFFF",
                zIndex: 30,
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  opacity: hovered ? "1" : "0",
                  transform: hovered ? "translateY(0%)" : "translateY(-120%)",
                  transition: "all 0.3s ease-in-out",
                  padding: "10px",
                }}
              >
                {clientData?.ui.videoWidget.triggerTextHover}
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                display: "flex",
                alignItems: "center",
                color: "#FFFFFF",
                zIndex: 30,
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  textAlign: "center",
                  opacity: hovered ? "0" : "1",
                  transform: hovered ? "translateY(120%)" : "translateY(0%)",
                  transition: "all 0.3s ease-in-out",
                  padding: "10px",
                }}
              >
                {clientData?.ui.videoWidget.triggerText}
              </div>
            </div>
          </div>
          <Modal
            isOpen={isOpen}
            closeModal={closeModal}
            clientData={clientData}
          />
        </>
      )}
      {clientData?.ui.chatWidget.isActive && (
        <ChatWidget isOpen={isOpen} clientData={clientData} />
      )}
    </div>
  );
};

function injectStyle(cssContent) {
  let stylesheet = document.createElement("style");
  document.getElementById("smp-widget")?.appendChild(stylesheet);
  stylesheet.innerHTML = cssContent;
}
