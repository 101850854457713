import React, { useEffect } from "react";
import { FloorplanType } from "./Types";
import { useNavigate, useParams } from "react-router-dom";
import useGTM from "./hooks/useGTM";

const FloorplanDetails = ({
  data,
  setData,
  clientId,
  bookTourLink,
}: {
  data: FloorplanType | null;
  setData: (id: string) => void;
  clientId?: string | number;
  bookTourLink?: string;
}) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const pushEventToDataLayer = useGTM();

  useEffect(() => {
    if (id) setData(id);
  }, [id, setData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <img
          src={data?.image}
          alt=""
          style={{ height: "320px", objectFit: "contain" }}
        />
        <div
          style={{
            padding: "5px 15px",
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div
            style={{ fontSize: "20px", fontWeight: "600", color: "#201f1e" }}
          >
            {data?.name}
          </div>
          <div className="w-floorplan-details">
            {data?.bedsCount
              ? data.bedsCount + " Bedroom(s) / "
              : "0 Bedroom(s) / "}
            {data?.bathsCount} Bath(s)
          </div>
          <div className="w-floorplan-details">
            ${data?.price.min} - ${data?.price.max}
          </div>
          <div className="w-floorplan-details">
            {data?.size} {data?.sizeUnits}
          </div>
          <div style={{ marginTop: "10px" }}>
            <div
              className="w-floorplan-button"
              style={{
                border: "1px solid var(--w-primary-color)",
                background: "var(--w-primary-color)" || "#3898ec",
                color: "#fff",
              }}
              onClick={() => {
                // navigate(`/video/floorplans/floorplan/${data?.name}/book`)
                if (bookTourLink) {
                  window.open(bookTourLink, "_blank");
                  pushEventToDataLayer(clientId + " show_book_tour", {
                    event_category: "button",
                    event_action: "click",
                    event_label: "booktour_button",
                    page_path: `/video/floorplans/floorplan/${data?.name}/book`,
                    page_client_id: clientId,
                  });
                }
              }}
            >
              Book Self-Guided Tour
            </div>
            <div
              className="w-floorplan-button"
              style={{
                border: "1px solid var(--w-primary-color)",
                background: "transparent",
                color: "var(--w-primary-color)" || "#3898ec",
              }}
              onClick={() => {
                navigate(
                  `/video/floorplans/floorplan/${data?.name}/ask-question`
                );
                pushEventToDataLayer(clientId + " ask_question_floorplan", {
                  event_category: "button",
                  event_action: "click",
                  event_label: "askquestion_button",
                  page_path: `/video/floorplans/floorplan/${data?.name}/ask-question`,
                  page_client_id: clientId,
                });
              }}
            >
              Ask a Question
            </div>
          </div>
          {/* <button
            style={{
              marginTop: "15px",
              color: "var(--w-primary-color)" || "rgb(100 116 139)",
              fontSize: "15px",
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
            onClick={toggleToAskQuestion}
          >
            Ask a question
          </button> */}
        </div>
      </div>
    </>
  );
};

export default FloorplanDetails;
