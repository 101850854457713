import React from "react";
import { shapeWidgetStyle } from "./Styles";

const CircleComponent = ({
  videoUrl,
  posterUrl,
}: {
  videoUrl?: string;
  posterUrl?: string;
}) => {
  return (
    <div
      style={{
        ...shapeWidgetStyle,
        ...{
          width: "92%",
          height: "92%",
          overflow: "hidden",
          zIndex: "20",
          animation: "wh-returns-original 0.5s 4s both",
        },
      }}
    >
      <video
        autoPlay
        muted
        loop
        playsInline
        controls={false}
        src={videoUrl}
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          cursor: "pointer",
        }}
        poster={posterUrl}
      >
        Your browser does not support the video.
      </video>
    </div>
  );
};

export default CircleComponent;
