import React, { useState } from "react";

interface PlanButtonProps {
  order: string;
  text: string;
  isSm: boolean;
  isLg: boolean;
  onClick?: () => void;
  isShownMenu: boolean;
  isShownInstagram: boolean;
}

const PlanButton = ({
  order,
  text,
  isSm,
  onClick,
  isLg,
  isShownMenu,
  isShownInstagram,
}: PlanButtonProps) => {
  const [hovered, setHovered] = useState(false);
  return (
    <button
      style={{
        textAlign: "left",
        border: "1px solid",
        pointerEvents: "auto",
        transition: "all 0.15s",
        borderColor: "#6B7280",
        backgroundColor: hovered ? "rgba(0,0,0,0.4)" : "rgba(0, 0, 0, 0.2)",
        borderRadius: "9999px",
        fontSize: "0.875rem",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        cursor: "pointer",
        padding: isSm ? "14px 7px" : "14px 5px",
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      <div
        style={{
          display: !isSm ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          fontWeight: "500",
          color:
            (isShownMenu && !isLg) || isShownInstagram ? "#4a4949" : "#fff",
          fontSize: "20px",
          marginLeft: "1.25rem",
        }}
      >
        {order}
      </div>
      <div
        style={{
          display: !isSm ? "flex" : "none",
          width: "1px",
          height: "28px",
          background:
            (isShownMenu && !isLg) || isShownInstagram ? "#4a4949" : "#fff",
          borderRadius: "10px",
          marginLeft: "10px",
          marginRight: "10px",
        }}
      ></div>
      <div
        style={{
          color:
            (isShownMenu && !isLg) || isShownInstagram ? "#4a4949" : "#fff",
          paddingLeft: isSm ? "1rem" : "",
          paddingRight: isSm ? "1rem" : "",
        }}
      >
        {text}
      </div>
    </button>
  );
};

export default PlanButton;
