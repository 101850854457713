import React from "react";
import { ClientData } from "./Types.ts";

const AskQuestion = ({
  clientData,
  apartmentName,
}: {
  clientData?: ClientData;
  apartmentName?: string;
}) => {
  return (
    <div
      style={{
        overflowY: "auto",
      }}
    >
      <div
        style={{
          padding: "11px 15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#201f1e",
              textAlign: "center",
            }}
          >
            Ask a Question about {apartmentName} Floor Plan
          </div>
          <div
            style={{
              overflowY: "auto",
              width: "100%",
              height: "500px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <iframe
              title="Contact Form"
              allowFullScreen={true}
              allow="geolocation; microphone; camera"
              src={`https://my.forms.app/form/${clientData?.ui.videoWidget.askQuestionFormId}#${clientData?.ui.videoWidget.askQuestionFormParameterId}=I'm interested in ${apartmentName} floor plan.`}
              style={{
                width: "100%",
                height: "100%",
                border: "none",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskQuestion;
