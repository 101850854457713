import React from "react";
import { ClientData, FloorplanType } from "./Types";
import BookTour from "./BookTour";
import AskQuestion from "./AskQuestion";

const FloorplanDetails = ({
  data,
  activeComponent,
  toggleToBookTour,
  toggleToAskQuestion,
  clientData,
  isLg,
  isMd,
}: {
  data: FloorplanType | null;
  activeComponent: string;
  toggleToBookTour: () => void;
  toggleToAskQuestion: () => void;
  clientData?: ClientData;
  isLg: boolean;
  isMd: boolean;
}) => {
  return (
    <>
      <div
        style={{
          display: activeComponent === "floorplanDetails" ? "flex" : "none",
          flexDirection: "column",
          marginBottom: "10px",
        }}
      >
        <img
          src={data?.image}
          alt=""
          style={{ height: "320px", objectFit: "contain" }}
        />
        <div
          style={{
            padding: "5px 15px",
            display: "flex",
            flexDirection: "column",
            marginTop: "10px",
          }}
        >
          <div
            style={{ fontSize: "20px", fontWeight: "600", color: "#201f1e" }}
          >
            {data?.name}
          </div>
          <div
            style={{
              color: "#3c4858",
              fontSize: "14px",
              marginBottom: "3px",
            }}
          >
            {data?.bedsCount
              ? data.bedsCount + " Bedroom(s) / "
              : "0 Bedroom(s) / "}
            {data?.bathsCount} Bath(s)
          </div>
          <div
            style={{
              color: "#3c4858",
              fontSize: "14px",
              marginBottom: "3px",
            }}
          >
            ${data?.price.min} - ${data?.price.max}
          </div>
          <div
            style={{
              color: "#3c4858",
              fontSize: "14px",
              marginBottom: "3px",
            }}
          >
            {data?.size} {data?.sizeUnits}
          </div>
          <div style={{ marginTop: "10px" }}>
            <div
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "15px",
                textAlign: "center",
                border: "1px solid var(--w-primary-color)",
                background: "var(--w-primary-color)" || "#3898ec",
                color: "#fff",
                cursor: "pointer",
                marginBottom: "10px",
                pointerEvents: "auto",
                transitionDuration: "150ms",
                borderRadius: "9999px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={toggleToBookTour}
            >
              Book a Tour
            </div>
            <div
              style={{
                width: "100%",
                padding: "10px",
                fontSize: "15px",
                textAlign: "center",
                border: "1px solid var(--w-primary-color)",
                background: "transparent",
                color: "var(--w-primary-color)" || "#3898ec",
                cursor: "pointer",
                pointerEvents: "auto",
                transitionDuration: "150ms",
                borderRadius: "9999px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={toggleToAskQuestion}
            >
              Ask a Question
            </div>
          </div>
          {/* <button
            style={{
              marginTop: "15px",
              color: "var(--w-primary-color)" || "rgb(100 116 139)",
              fontSize: "15px",
              border: "none",
              background: "none",
              cursor: "pointer",
            }}
            onClick={toggleToAskQuestion}
          >
            Ask a question
          </button> */}
        </div>
      </div>
      {activeComponent === "bookTour" && (
        <BookTour
          apartmentName={data?.name}
          calendlyUrl={clientData?.ui.videoWidget.calendlyUrl}
          isLg={isLg}
          isMd={isMd}
        />
      )}
      {activeComponent === "askQuestion" && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AskQuestion clientData={clientData} apartmentName={data?.name} />
        </div>
      )}
    </>
  );
};

export default FloorplanDetails;
