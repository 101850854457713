import React, { useEffect, useState } from "react";
import FloorplanCard from "./FloorplanCard";
import { FloorplanType } from "./Types";
import FilterFloorplans from "./FilterFloorplans";

// import dayjs from "dayjs";

const ListOfFloorplans = ({
  clientId,
  floorplans,
  showDetails,
  bedFilter,
  bathFilter,
  priceFilter,
  scrollRef,
  isSm,
  isMd,
  handleBedFilter,
  handleBathFilter,
  handlePriceFilter,
}: {
  clientId?: string | number;
  floorplans?: FloorplanType[];
  showDetails: (data: FloorplanType) => void;
  bedFilter: number;
  bathFilter: number;
  priceFilter: number[];
  scrollRef: React.RefObject<HTMLDivElement>;
  isSm: boolean;
  isMd: boolean;
  handleBedFilter: (value: number) => void;
  handleBathFilter: (value: number) => void;
  handlePriceFilter: (value: number[]) => void;
}) => {
  const [dataToShow, setDataToShow] = useState<FloorplanType[] | undefined>(
    floorplans
  );
  const [restData, setRestData] = useState<FloorplanType[] | undefined>();

  useEffect(() => {
    const handleFilterData = () => {
      const filteredData: FloorplanType[] = [];
      const remainingData: FloorplanType[] = [];

      floorplans?.forEach((item) => {
        const isWithinPriceRange =
          item.price.min >= priceFilter[0] && item.price.min <= priceFilter[1];
        const hasRequiredBeds =
          bedFilter === 0 ? true : item.bedsCount === bedFilter;
        const hasRequiredBaths =
          bathFilter === 0 ? true : item.bathsCount === bathFilter;

        if (isWithinPriceRange && hasRequiredBaths && hasRequiredBeds) {
          filteredData.push(item);
        } else {
          remainingData.push(item);
        }
      });

      setDataToShow(filteredData);
      setRestData(remainingData);
    };
    handleFilterData();
  }, [floorplans, bathFilter, bedFilter, priceFilter]);

  return (
    <div
      style={{
        overflowY: "auto",
        maxHeight: "100vh",
        paddingBottom: isSm || isMd ? "60px" : "unset",
      }}
      ref={scrollRef}
    >
      <div
        style={{
          padding: "11px 15px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#fff",
            padding: "12px",
            borderBottom: "1px solid var(--w-tertiary-color)",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: 600,
              color: "#201f1e",
            }}
          >
            Floor Plans
          </div>
          <div
            style={{
              fontSize: "14px",
              fontWeight: "300",
              color: "#3c4858",
              marginTop: "5px",
              textWrap: "pretty",
            }}
          >
            Browse the matching floor plans below.
          </div>

          <FilterFloorplans
            priceFilter={priceFilter}
            bedFilter={bedFilter}
            bathFilter={bathFilter}
            handleBedFilter={handleBedFilter}
            handleBathFilter={handleBathFilter}
            handlePriceFilter={handlePriceFilter}
            isSm={isSm}
          />
        </div>
        <div style={{ marginTop: "14px" }}>
          {dataToShow &&
            dataToShow.map((floorplan, id) => (
              <FloorplanCard
                floorplan={floorplan}
                key={id}
                handleClick={() => showDetails(floorplan)}
                isSm={isSm}
                isMd={isMd}
              />
            ))}
        </div>
        <div style={{ paddingTop: dataToShow?.length === 0 ? "0" : "2rem" }}>
          <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
            Not seeing what you're looking for?
          </h1>
          <p style={{ color: "rgb(107 114 128)" }}>
            We have a few more floor plans that we can waitlist you for:
          </p>
        </div>
        <div>
          {restData &&
            restData.map((floorplan, id) => (
              <FloorplanCard
                floorplan={floorplan}
                key={id}
                handleClick={() => showDetails(floorplan)}
                opacity={0.5}
                isSm={isSm}
                isMd={isMd}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default ListOfFloorplans;
