import React, { useEffect, useState } from "react";
import { ClientData } from "./Types";
import axios from "axios";
import InstagramModal from "./InstagramModal";
import InstagramPost from "./InstagramPost";

export interface Post {
  id: string;
  caption: string;
  media_url: string;
  media_type: string;
  permalink: string;
  thumbnail_url: string;
  username: string;
  timestamp: string;
  children?: {
    data: {
      id: string;
    }[];
  };
}

interface ActiveMedia {
  id: number | null;
  status: boolean;
}

interface InstagramFeedProps {
  toggleShowInstagram: () => void;
  clientData?: ClientData;
  isMd: boolean;
  isSm: boolean;
  isLg: boolean;
  isActiveMenuButtons: boolean;
}

const MAX_CHARS = 30;

export interface CarouselContent {
  id: string;
  media_url: string;
  media_type: string;
  permalink: string;
  thumbnail_url: string;
}

export interface CarouselItem {
  postId?: string | number;
  carouselContent: CarouselContent[];
  currentIndex: number;
}
const InstagramFeed = ({
  toggleShowInstagram,
  clientData,
  isMd,
  isSm,
  isLg,
  isActiveMenuButtons,
}: InstagramFeedProps) => {
  const [posts, setPosts] = useState<Post[]>();
  const [hovered, setHovered] = useState<ActiveMedia>();

  const [isOpen, setIsOpen] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState<number | null>(null);

  const [carousel, setCarousel] = useState<CarouselItem[]>([]);

  const openModal = (index: number) => {
    setActiveImageIndex(index);
    setCarousel((prevCarousel) =>
      prevCarousel.map((item) => ({ ...item, currentIndex: 0 }))
    );

    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setActiveImageIndex(null);
  };

  const truncatedText = (text: string) => {
    if (text.length <= MAX_CHARS) return text;

    const trimmedText = text.slice(0, MAX_CHARS);
    const lastSpaceIndex = trimmedText.lastIndexOf(" ");

    if (lastSpaceIndex === -1) {
      return trimmedText + "..."; // If there's no space, cut at maxChars.
    }

    return trimmedText.slice(0, lastSpaceIndex) + "..."; // Truncate at last space.
  };

  useEffect(() => {
    if (isOpen && activeImageIndex !== null) {
      const activeImageElement = document.getElementById(
        `image-${activeImageIndex}`
      );
      if (activeImageElement) {
        activeImageElement.scrollIntoView({
          block: "start",
        });
      }
    }
  }, [isOpen, activeImageIndex]);

  useEffect(() => {
    const fetchFeed = async () => {
      try {
        if (clientData?.id) {
          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/instagram-feed/${clientData.id}`
          );
          setPosts(response.data.data);
        }
      } catch (error) {
        console.error("There was a problem with the fetching data: ", error);
      }
    };

    fetchFeed();
  }, [clientData]);

  useEffect(() => {
    const fetchDataFromCarousel = async () => {
      if (posts && posts?.length > 0) {
        posts?.map(async (post, index) => {
          if (post.media_type === "CAROUSEL_ALBUM") {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_API_URL}/instagram-carousel?smpClientId=${clientData?.id}&postId=${post.id}`
              );
              setCarousel((prevState) => [
                ...prevState,
                {
                  postId: post.id,
                  carouselContent: response.data.data,
                  currentIndex: index,
                },
              ]);
            } catch (error) {
              console.error(
                "There was a problem with the fetching data: ",
                error
              );
            }
          }
        });
      }
    };

    fetchDataFromCarousel();
  }, [posts, clientData?.id]);

  const handleRightArrowClick = (postId: string | number) => {
    setCarousel((prevCarousel) =>
      prevCarousel.map((item) =>
        item.postId === postId
          ? {
              ...item,
              currentIndex:
                item.currentIndex < item.carouselContent.length - 1
                  ? item.currentIndex + 1
                  : item.currentIndex,
            }
          : item
      )
    );
  };

  const handleLeftArrowClick = (postId: string | number) => {
    setCarousel((prevCarousel) =>
      prevCarousel.map((item) =>
        item.postId === postId
          ? {
              ...item,
              currentIndex: item.currentIndex > 0 ? item.currentIndex - 1 : 0,
            }
          : item
      )
    );
  };

  const username = posts && posts[0].username;
  const profileUrl = "https://instagram.com/" + username;
  const postEls =
    posts &&
    posts.map((post, index) => {
      // IMAGE or CAROUSEL_ALBUM
      let mediaEl = (
        <img
          src={post.media_url}
          alt=""
          style={{
            height: "100%",
            width: "100%",
            objectFit: "cover",
            transform:
              hovered?.id === index && hovered.status
                ? "scale(1.1)"
                : "scale(1)",
            transition: "all 0.3s ease",
          }}
          onClick={() => openModal(index)}
        />
      );

      // VIDEO
      if (post.media_type === "VIDEO") {
        mediaEl = (
          <video
            poster={post.thumbnail_url}
            src={post.media_url}
            muted
            webkit-playsinline="true"
            playsInline
            autoPlay
            style={{
              height: "100%",
              width: "100%",
              objectFit: "cover",
              transform:
                hovered?.id === index && hovered.status
                  ? "scale(1.1)"
                  : "scale(1)",
              transition: "all 0.3s ease",
            }}
            onClick={() => openModal(index)}
          ></video>
        );
      }

      return (
        <div
          style={{
            width: "100%",
            height: "200px",
            position: "relative",
            overflow: "hidden",
            borderRadius: "4px",
            cursor: "pointer",
          }}
          key={index}
          data-index={index}
          onMouseEnter={() => setHovered({ id: index, status: true })}
          onMouseLeave={() => setHovered({ id: index, status: false })}
        >
          {/* overlay */}
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              zIndex: 20,
              background: "rgba(0,0,0,0.5)",
              transition: "all 0.3s ease",
              pointerEvents: "none",
              opacity: hovered?.id === index && hovered.status ? "1" : "0",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "5px",
                textAlign: "center",
                color: "#fff",
                width: "100%",
                height: "100%",
              }}
            >
              {truncatedText(post.caption)}
            </div>
          </div>

          {mediaEl}
        </div>
      );
    });

  return (
    <div
      style={{
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "0px",
        overflowY: "auto",
        padding: "0 5px",
      }}
    >
      <div
        style={{
          width: "90%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <img
          src={`${process.env.REACT_APP_API_URL}/assets/instagram-logo.png`}
          alt="logo"
          style={{ height: "55px" }}
        />
        <button
          onClick={toggleShowInstagram}
          style={{
            borderWidth: 0,
            cursor: "pointer",
            background: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2"
            stroke="currentColor"
            style={{
              width: "1.5rem",
              height: "1.5rem",
              color: "#000000",
            }}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            ></path>
          </svg>
        </button>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "90%",
          paddingBottom: "5px",
        }}
      >
        <a
          href={profileUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "none",
            background: "none",
            fontSize: "15px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "10px",
            color: "#000",
            padding: "4px",
            borderRadius: "5px",
            fontWeight: "500",
          }}
        >
          @{username}
        </a>
        <div>
          <a
            href={profileUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none",
              background: "none",
              fontSize: "15px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              color: "rgb(0, 149, 246)",
              padding: "4px",
              borderRadius: "5px",
              width: "100px",
            }}
          >
            Follow
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 50 50"
              strokeWidth="1.7"
              stroke="currentColor"
              fill="none"
              style={{
                width: "24px",
                height: "24px",
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                color: "#000",
              }}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M 16 3 C 8.8324839 3 3 8.8324839 3 16 L 3 34 C 3 41.167516 8.8324839 47 16 47 L 34 47 C 41.167516 47 47 41.167516 47 34 L 47 16 C 47 8.8324839 41.167516 3 34 3 L 16 3 z M 16 5 L 34 5 C 40.086484 5 45 9.9135161 45 16 L 45 34 C 45 40.086484 40.086484 45 34 45 L 16 45 C 9.9135161 45 5 40.086484 5 34 L 5 16 C 5 9.9135161 9.9135161 5 16 5 z M 37 11 A 2 2 0 0 0 35 13 A 2 2 0 0 0 37 15 A 2 2 0 0 0 39 13 A 2 2 0 0 0 37 11 z M 25 14 C 18.936712 14 14 18.936712 14 25 C 14 31.063288 18.936712 36 25 36 C 31.063288 36 36 31.063288 36 25 C 36 18.936712 31.063288 14 25 14 z M 25 16 C 29.982407 16 34 20.017593 34 25 C 34 29.982407 29.982407 34 25 34 C 20.017593 34 16 29.982407 16 25 C 16 20.017593 20.017593 16 25 16 z"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div
        style={{
          height: "100%",
          width: "auto",
          display: "grid",
          placeItems: "center",
          gridTemplateColumns: `repeat(${
            isLg || isMd ? "3" : "2"
          }, minmax(0, 1fr))`,
          gap: "5px",
          marginTop: "6px",
          paddingBottom: "5px",
          overflowY: "auto",
        }}
      >
        {posts ? postEls : <div style={{ height: "200vh" }}></div>}
        <InstagramModal
          isOpen={isOpen}
          onClose={closeModal}
          isLg={isLg}
          isMd={isMd}
        >
          <div style={{ display: "block" }}>
            {posts &&
              posts.map((post, index) => {
                const currentCarousel = carousel.find(
                  (item) => item.postId === post.id
                );

                return (
                  <div key={index}>
                    <InstagramPost
                      post={post}
                      currentCarousel={currentCarousel}
                      handleRightArrowClick={() =>
                        handleRightArrowClick(post.id)
                      }
                      handleLeftArrowClick={() => handleLeftArrowClick(post.id)}
                      id={`image-${index}`}
                    />
                  </div>
                );
              })}
          </div>
        </InstagramModal>
      </div>
    </div>
  );
};

export default InstagramFeed;
