import React, { useEffect } from "react";
import { InlineWidget } from "react-calendly";
import { useParams } from "react-router-dom";

const BookTour = ({
  apartmentName,
  calendlyUrl,
  isMd,
  isLg,
  setData,
}: {
  apartmentName?: string;
  calendlyUrl?: string;
  isMd: boolean;
  isLg: boolean;
  setData?: (id: string) => void;
}) => {
  const { id } = useParams();

  useEffect(() => {
    if (id && setData) setData(id);
  }, [id, setData]);

  return (
    <div
      className="w-menu-container"
      style={{
        paddingBottom: !isLg ? "64px" : "unset",
      }}
    >
      {calendlyUrl ? (
        <InlineWidget
          url={calendlyUrl}
          pageSettings={{
            backgroundColor: "fff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "6b7280",
            textColor: "000",
            hideGdprBanner: true,
          }}
          styles={{
            height: "100vh",
            marginTop: isLg || isMd ? "-50px" : "0",
            marginLeft: isLg ? "-30px" : "0",
            marginBottom: isLg ? "5px" : "0",
          }}
          prefill={{
            customAnswers: {
              a1: "",
              a2: apartmentName ? apartmentName : "",
            },
          }}
        />
      ) : (
        <div>Booking tour currently unavailable</div>
      )}

      {/* <iframe
        title="book a tour"
        src="https://selftournow.com/property/6214ccc7-987d-4c1b-8b1e-04e4cc22e963/units"
      /> */}
    </div>
  );
};

export default BookTour;
