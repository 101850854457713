import React, { useEffect, useState } from "react";
import FloorplanCard from "./FloorplanCard";
import { FloorplanType } from "./Types";
import FilterFloorplans from "./FilterFloorplans";

// import dayjs from "dayjs";

const ListOfFloorplans = ({
  floorplans,
  showDetails,
  activeComponent,
  bedFilter,
  bathFilter,
  priceFilter,
  scrollRef,
  isSm,
  isMd,
  handleBedFilter,
  handleBathFilter,
  handlePriceFilter,
}: {
  floorplans?: FloorplanType[];
  showDetails: (data: FloorplanType) => void;
  activeComponent: string;
  bedFilter: number;
  bathFilter: number;
  priceFilter: number[];
  scrollRef: React.RefObject<HTMLDivElement>;
  isSm: boolean;
  isMd: boolean;
  handleBedFilter: (value: number) => void;
  handleBathFilter: (value: number) => void;
  handlePriceFilter: (value: number[]) => void;
}) => {
  const [dataToShow, setDataToShow] = useState<FloorplanType[] | undefined>(
    floorplans
  );
  const [restData, setRestData] = useState<FloorplanType[] | undefined>();

  useEffect(() => {
    const handleFilterData = () => {
      const filteredData: FloorplanType[] = [];
      const remainingData: FloorplanType[] = [];

      floorplans?.forEach((item) => {
        const isWithinPriceRange =
          item.price.min >= priceFilter[0] && item.price.min <= priceFilter[1];
        const hasRequiredBeds =
          bedFilter === 0 ? true : item.bedsCount === bedFilter;
        const hasRequiredBaths =
          bathFilter === 0 ? true : item.bathsCount === bathFilter;

        if (isWithinPriceRange && hasRequiredBaths && hasRequiredBeds) {
          filteredData.push(item);
        } else {
          remainingData.push(item);
        }
      });

      setDataToShow(filteredData);
      setRestData(remainingData);
    };
    handleFilterData();
  }, [floorplans, bathFilter, bedFilter, priceFilter]);

  return (
    <div
      style={{
        padding: "11px 15px",
        display: activeComponent === "listFloorplans" ? "flex" : "none",
        flexDirection: "column",
      }}
      ref={scrollRef}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#fff",
          padding: "12px",
          borderBottom: "1px solid var(--w-tertiary-color)",
        }}
      >
        <div
          style={{
            fontSize: "20px",
            fontWeight: 600,
            color: "#201f1e",
          }}
        >
          Floor Plans
        </div>
        <div
          style={{
            fontSize: "14px",
            fontWeight: "300",
            color: "#3c4858",
            marginTop: "5px",
            textWrap: "pretty",
          }}
        >
          Browse the matching floor plans below.
        </div>

        <FilterFloorplans
          priceFilter={priceFilter}
          bedFilter={bedFilter}
          bathFilter={bathFilter}
          handleBedFilter={handleBedFilter}
          handleBathFilter={handleBathFilter}
          handlePriceFilter={handlePriceFilter}
          isSm={isSm}
        />
      </div>
      <div style={{ marginTop: "14px" }}>
        {dataToShow &&
          dataToShow.map((floorplan, id) => (
            <FloorplanCard
              name={floorplan.name}
              price={floorplan.price}
              bedCount={floorplan?.bedsCount}
              bathCount={floorplan.bathsCount}
              size={floorplan.size}
              sizeUnits={floorplan.sizeUnits}
              img={floorplan.image}
              key={id}
              handleClick={() => showDetails(floorplan)}
              isSm={isSm}
              isMd={isMd}
            />
          ))}
      </div>
      <div style={{ paddingTop: dataToShow?.length === 0 ? "0" : "2rem" }}>
        <h1 style={{ fontSize: "24px", fontWeight: "600" }}>
          Not seeing what you're looking for?
        </h1>
        <p style={{ color: "rgb(107 114 128)" }}>
          We have a few more floor plans that we can waitlist you for:
        </p>
      </div>
      <div>
        {restData &&
          restData.map((floorplan, id) => (
            <FloorplanCard
              name={floorplan.name}
              price={floorplan.price}
              bedCount={floorplan?.bedsCount}
              bathCount={floorplan.bathsCount}
              img={floorplan.image}
              key={id}
              handleClick={() => showDetails(floorplan)}
              opacity={0.5}
              isSm={isSm}
              isMd={isMd}
              size={floorplan.size}
              sizeUnits={floorplan.sizeUnits}
            />
          ))}
      </div>
    </div>
  );
};

export default ListOfFloorplans;
