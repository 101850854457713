import React from "react";

const RadientSvg = () => {
  return (
    <svg
      viewBox="0 0 458 458"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        position: "absolute",
        width: "100%",
        right: 0,
        top: "50%",
        left: "50%",
        transform: "translate(-50%, 0%)",
        zIndex: 0,
        opacity: "0.8",
      }}
    >
      <g opacity="0.5">
        <g opacity="0.64" filter="url(#filter0_f_1_176)">
          <rect
            x="346.757"
            y="347.757"
            width="237.514"
            height="237.514"
            rx="118.757"
            transform="rotate(-180 346.757 347.757)"
            stroke="url(#paint0_linear_1_176)"
            strokeWidth="12"
          />
        </g>
        <g opacity="0.48" filter="url(#filter1_f_1_176)">
          <rect
            x="383"
            y="384"
            width="310"
            height="310"
            rx="155"
            transform="rotate(-180 383 384)"
            stroke="url(#paint1_linear_1_176)"
            strokeWidth="12"
          />
        </g>
        <g opacity="0.24" filter="url(#filter2_f_1_176)">
          <rect
            x="419"
            y="419"
            width="380"
            height="380"
            rx="190"
            transform="rotate(-180 419 419)"
            stroke="url(#paint2_linear_1_176)"
            strokeWidth="12"
          />
        </g>
        <g filter="url(#filter3_f_1_176)">
          <rect
            x="310.514"
            y="311.514"
            width="165.027"
            height="165.027"
            rx="82.5135"
            transform="rotate(-180 310.514 311.514)"
            stroke="url(#paint3_linear_1_176)"
            strokeWidth="12"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_f_1_176"
          x="81.497"
          y="82.497"
          width="293.006"
          height="293.006"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.8731"
            result="effect1_foregroundBlur_1_176"
          />
        </filter>
        <filter
          id="filter1_f_1_176"
          x="45.2538"
          y="46.2537"
          width="365.493"
          height="365.493"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="10.8731"
            result="effect1_foregroundBlur_1_176"
          />
        </filter>
        <filter
          id="filter2_f_1_176"
          x="0.380634"
          y="0.380604"
          width="457.239"
          height="457.239"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="16.3097"
            result="effect1_foregroundBlur_1_176"
          />
        </filter>
        <filter
          id="filter3_f_1_176"
          x="120.459"
          y="121.458"
          width="215.083"
          height="215.083"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="9.51399"
            result="effect1_foregroundBlur_1_176"
          />
        </filter>
        <linearGradient
          id="paint0_linear_1_176"
          x1="349.376"
          y1="358.994"
          x2="349.376"
          y2="567.27"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="0.314412" stopColor="#919192" />
          <stop offset="1" stopColor="#6A6B6C" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_176"
          x1="388.389"
          y1="400.778"
          x2="388.389"
          y2="676"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="0.314412" stopColor="#919192" />
          <stop offset="1" stopColor="#6A6B6C" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_176"
          x1="427.064"
          y1="441.129"
          x2="427.064"
          y2="781"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="0.314412" stopColor="#919192" />
          <stop offset="1" stopColor="#6A6B6C" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_1_176"
          x1="310.362"
          y1="317.21"
          x2="310.362"
          y2="458.541"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDEDED" />
          <stop offset="0.314412" stopColor="#919192" />
          <stop offset="1" stopColor="#6A6B6C" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RadientSvg;
